import {
  CREATE_SHIFT,
  CREATE_SHIFT_FAILURE,
  CREATE_SHIFT_SUCCESS,
  DELETE_JOB,
  DELETE_JOB_FAILURE,
  DELETE_JOB_SUCCESS,
  DELETE_SHIFT,
  DELETE_SHIFT_FAILURE,
  DELETE_SHIFT_SUCCESS,
  EDIT_SHIFT,
  EDIT_SHIFT_ASSIGNEES_ADD,
  EDIT_SHIFT_ASSIGNEES_ADD_FAILURE,
  EDIT_SHIFT_ASSIGNEES_ADD_SUCCESS,
  EDIT_SHIFT_ASSIGNEES_REMOVE,
  EDIT_SHIFT_ASSIGNEES_REMOVE_FAILURE,
  EDIT_SHIFT_ASSIGNEES_REMOVE_SUCCESS,
  EDIT_SHIFT_FAILURE,
  EDIT_SHIFT_SUCCESS,
  GET_JOB_SHIFTS_LIST,
  GET_JOB_SHIFTS_LIST_FAILURE,
  GET_JOB_SHIFTS_LIST_SUCCESS,
  GET_OFFICE_SHIFTS_LIST,
  GET_OFFICE_SHIFTS_LIST_FAILURE,
  GET_OFFICE_SHIFTS_LIST_SUCCESS,
  GET_SHIFT_DETAILS,
  GET_SHIFT_DETAILS_FAILURE,
  GET_SHIFT_DETAILS_SUCCESS,
  UPDATE_JOB_STATUS,
  UPDATE_JOB_STATUS_FAILURE,
  UPDATE_JOB_STATUS_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

const noInternetMsg =
  'No Internet Connection! Please connect to the internet and try again';

//get shifts list
export const getOfficeShiftsListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getOfficeShiftsListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      officeShiftsList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    officeShiftsList: [],
  };
};
export const getOfficeShiftsListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    officeShiftsList: [],
  };
};

export const getJobShiftsListRequest = (state, action) => {
  return {
    ...state,
    jobListIsLoading: true,
  };
};

export const getJobShiftsListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      jobListIsLoading: false,
      totaljobsCount: data.data.count ? data.data.count : null,
      pageSizeJob: data.data.page_size ? data.data.page_size : null,
      jobShiftsList: data.data.results ? data.data.results : data.data,
    };
  }
  return {
    ...state,
    jobListIsLoading: false,
    jobShiftsList: [],
    totaljobsCount: null,
    pageSizeJob: null,
  };
};
export const getJobShiftsListRequestFailure = (state, action) => {
  return {
    ...state,
    jobListIsLoading: false,
    jobShiftsList: [],
    totaljobsCount: null,
    pageSizeJob: null,
  };
};

// create shift
export const createShiftRequest = (state, action) => {
  return {
    ...state,
    createIsLoading: true,
    createCallback: false,
  };
};
export const createShiftRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Shift created successfully', 2000);
    return {
      ...state,
      createIsLoading: false,
      createCallback: true,
      createdShiftInfo: data.data,
    };
  }
  return {
    ...state,
    createIsLoading: false,
    createCallback: false,
    createdShiftInfo: null,
  };
};
export const createShiftRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail &&
    response.data.detail === 'employee has another shift within the given time'
  ) {
    createNotification(
      'error',
      `Employee has another shift within the given time`,
      5000,
    );
    return {
      ...state,
      createIsLoading: false,
      createCallback: false,
      createdShiftInfo: null,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went Wrong.', 3000);
    return {
      ...state,
      createIsLoading: false,
      createCallback: false,
      createdShiftInfo: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      'error',
      'Sorry! could not create Shift. Please try again.',
      2000,
    );
  }
  return {
    ...state,
    createIsLoading: false,
    createCallback: false,
    createdShiftInfo: null,
  };
};

// Shift details by id
export const getShiftDetailsRequest = (state, action) => {
  return {
    ...state,
    detailsIsLoading: true,
  };
};
export const getShiftDetailsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      detailsIsLoading: false,
      shiftDetails: data.data,
    };
  }
  return {
    ...state,
    detailsIsLoading: false,
    shiftDetails: null,
  };
};
export const getShiftDetailsRequestFailure = (state, action) => {
  return {
    ...state,
    detailsIsLoading: false,
    shiftDetails: null,
  };
};

//edit shift
export const editShiftRequest = (state, action) => {
  return {
    ...state,
    editIsLoading: true,
    editCallback: false,
  };
};
export const editShiftRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    createNotification('success', 'Shift updated successfully', 2000);
    return {
      ...state,
      editIsLoading: false,
      editCallback: true,
    };
  }
  return {
    ...state,
    editIsLoading: false,
    editCallback: false,
  };
};
export const editShiftRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail &&
    response.data.detail === 'employee has another shift within the given time'
  ) {
    createNotification(
      'error',
      `Employee has another shift within the given time`,
      5000,
    );
    return {
      ...state,
      editIsLoading: false,
      editCallback: false,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      editIsLoading: false,
      editCallback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    editIsLoading: false,
    editCallback: false,
  };
};

//edit-shift assignees add
export const editShiftAssigneesAddRequest = (state, action) => {
  return {
    ...state,
    editAssigneesIsLoading: true,
  };
};
export const editShiftAssigneesAddRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    return {
      ...state,
      editAssigneesIsLoading: false,
    };
  }
  // else if (data && data.status === 301) {
  //   createNotification('success', 'assignee removed successfully', 3000);
  //   return {
  //     ...state,
  //     editAssigneesIsLoading: false,
  //   };
  // }
  return {
    ...state,
    editAssigneesIsLoading: false,
  };
};
export const editShiftAssigneesAddRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      editAssigneesIsLoading: false,
      editedAssigneeList: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    //createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    editAssigneesIsLoading: false,
    editedAssigneeList: null,
  };
};

//edit-shift remove assignee
export const editShiftAssigneesRemoveRequest = (state, action) => {
  return {
    ...state,
    editAssigneesIsLoading: true,
    isAssigneeDeleted: false,
  };
};
export const editShiftAssigneesRemoveRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 204) {
    return {
      ...state,
      editAssigneesIsLoading: false,
      isAssigneeDeleted: true,
    };
  }
  return {
    ...state,
    editAssigneesIsLoading: false,
    isAssigneeDeleted: false,
  };
};
export const editShiftAssigneesRemoveRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not remove assignee. Please try again!',
    3000,
  );
  return {
    ...state,
    deleteIsLoading: false,
    isAssigneeDeleted: false,
  };
};

//DELETE SHIFT
export const deleteShiftRequest = (state, action) => {
  return {
    ...state,
    deleteIsLoading: true,
    deleteCallback: false,
  };
};
export const deleteShiftRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 204) {
    createNotification('success', 'Shift deleted successfully', 3000);
    return {
      ...state,
      deleteIsLoading: false,
      deleteCallback: true,
    };
  }
  return {
    ...state,
    deleteIsLoading: false,
    deleteCallback: false,
  };
};
export const deleteShiftRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 403 &&
    response.data &&
    response.data.error &&
    response.data.error === 'cannot delete past schedule'
  ) {
    createNotification('error', `Past time cannot be deleted`, 5000);
    return {
      ...state,
      deleteIsLoading: false,
      deleteCallback: false,
    };
  }
  createNotification(
    'error',
    'Could not delete shift. Please try again!',
    3000,
  );
  return {
    ...state,
    deleteIsLoading: false,
    deleteCallback: false,
  };
};

//Job Status Update
export const jobUpdateRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const jobUpdateRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, job_id, reloadJobList },
  } = action;
  if (data && data.status === 200) {
    createNotification('success', 'Job updated successfully', 2000);
    const currentList = JSON.parse(JSON.stringify(state.jobShiftsList));

    return {
      ...state,
      isLoading: false,
      reloadJobList,
      totaljobsCount: state.totaljobsCount - 1,
      jobShiftsList: currentList.filter(job => job.id !== job_id),
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const jobUpdateRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification(
      'error',
      'Something went wrong! Please try again.',
      3000,
    );
    return {
      ...state,
      isLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isLoading: false,
  };
};

// Delete Job
export const deleteJobHandler = (state, action) => {
  return {
    ...state,
    reloadJobList: null,
    jobDeleteLoading: true,
    deletingJobId: null,
  };
};
export const deleteJobSuccessHandler = (state, action) => {
  const {
    payload: { job_id, reloadJobList },
  } = action;
  createNotification('success', 'Job deleted successfully', 3000);
  return {
    ...state,
    reloadJobList,
    jobDeleteLoading: false,
    deletingJobId: job_id,
    jobShiftsList: state.jobShiftsList.filter(job => job.id !== job_id),
    totaljobsCount: state.totaljobsCount - 1,
  };
};
export const deleteJobFailureHandler = (state, action) => {
  createNotification('error', 'Could not delete. Please try again!', 3000);
  return {
    ...state,
    jobDeleteLoading: false,
    deletingJobId: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [DELETE_JOB]: deleteJobHandler,
  [DELETE_JOB_SUCCESS]: deleteJobSuccessHandler,
  [DELETE_JOB_FAILURE]: deleteJobFailureHandler,

  [GET_OFFICE_SHIFTS_LIST]: getOfficeShiftsListRequest,
  [GET_OFFICE_SHIFTS_LIST_SUCCESS]: getOfficeShiftsListRequestSuccess,
  [GET_OFFICE_SHIFTS_LIST_FAILURE]: getOfficeShiftsListRequestFailure,

  [GET_JOB_SHIFTS_LIST]: getJobShiftsListRequest,
  [GET_JOB_SHIFTS_LIST_SUCCESS]: getJobShiftsListRequestSuccess,
  [GET_JOB_SHIFTS_LIST_FAILURE]: getJobShiftsListRequestFailure,

  [CREATE_SHIFT]: createShiftRequest,
  [CREATE_SHIFT_SUCCESS]: createShiftRequestSuccess,
  [CREATE_SHIFT_FAILURE]: createShiftRequestFailure,

  [GET_SHIFT_DETAILS]: getShiftDetailsRequest,
  [GET_SHIFT_DETAILS_SUCCESS]: getShiftDetailsRequestSuccess,
  [GET_SHIFT_DETAILS_FAILURE]: getShiftDetailsRequestFailure,

  [EDIT_SHIFT]: editShiftRequest,
  [EDIT_SHIFT_SUCCESS]: editShiftRequestSuccess,
  [EDIT_SHIFT_FAILURE]: editShiftRequestFailure,

  [EDIT_SHIFT_ASSIGNEES_ADD]: editShiftAssigneesAddRequest,
  [EDIT_SHIFT_ASSIGNEES_ADD_SUCCESS]: editShiftAssigneesAddRequestSuccess,
  [EDIT_SHIFT_ASSIGNEES_ADD_FAILURE]: editShiftAssigneesAddRequestFailure,

  [EDIT_SHIFT_ASSIGNEES_REMOVE]: editShiftAssigneesRemoveRequest,
  [EDIT_SHIFT_ASSIGNEES_REMOVE_SUCCESS]: editShiftAssigneesRemoveRequestSuccess,
  [EDIT_SHIFT_ASSIGNEES_REMOVE_FAILURE]: editShiftAssigneesRemoveRequestFailure,

  [DELETE_SHIFT]: deleteShiftRequest,
  [DELETE_SHIFT_SUCCESS]: deleteShiftRequestSuccess,
  [DELETE_SHIFT_FAILURE]: deleteShiftRequestFailure,

  [UPDATE_JOB_STATUS]: jobUpdateRequestHandler,
  [UPDATE_JOB_STATUS_SUCCESS]: jobUpdateRequestSuccessHandler,
  [UPDATE_JOB_STATUS_FAILURE]: jobUpdateRequestFailureHandler,
};

// default initial state
const initialState = {
  // loading
  isLoading: false,
  jobListIsLoading: false,
  createIsLoading: false,
  deleteIsLoading: false,
  detailsIsLoading: false,
  editIsLoading: false,
  editAssigneesIsLoading: false,

  officeShiftsList: [],
  totaljobsCount: null,
  pageSizeJob: null,
  jobShiftsList: [],
  shiftDetails: null,
  editedAssigneeList: [],
  isAssigneeDeleted: false,
  createdShiftInfo: null,
  overlap: false,

  createCallback: false,
  editCallback: false,
  deleteCallback: false,

  jobDeleteLoading: false,
  deletingJobId: null,
  reloadJobList: null,
};

export default function ShiftScheduleReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
