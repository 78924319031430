import {
  DELETE_JOB,
  GET_JOBS_LIST,
  GET_JOBS_SHORT_LIST,
  GET_JOB_DETAILS,
  JOB_ASSIGNEE_ADD,
  JOB_ASSIGNEE_REMOVE,
  UPDATE_JOB_STATUS,
} from '../../modules/constants';

export function updateJobApproval(value) {
  return {
    type: UPDATE_JOB_STATUS,
    payload: value,
  };
}

export function getJobList(value) {
  return {
    type: GET_JOBS_LIST,
    payload: value,
  };
}

export function getjobsShortList(value) {
  return {
    type: GET_JOBS_SHORT_LIST,
    payload: value,
  };
}
export function getJobDetails(value) {
  return {
    type: GET_JOB_DETAILS,
    payload: value,
  };
}

export function removeJobAssignee(value) {
  return {
    type: JOB_ASSIGNEE_REMOVE,
    payload: value,
  };
}

export function addJobAssignee(value) {
  return {
    type: JOB_ASSIGNEE_ADD,
    payload: value,
  };
}

export function deleteJob(value) {
  return {
    type: DELETE_JOB,
    payload: value,
  };
}
