import moment from 'moment';
import { useEffect, useState } from 'react';

import avatar5 from '../../../assets/img/avatar_5.svg';
import {
  DailyTime,
  TableBodyContainer,
  TableBodyItem,
  TableContainer,
  TableHeadContainer,
  TableHeadItem,
  TimeRangeText,
  TimesheetDate,
  TotalTimeContainer,
  WeeklyTotal,
} from '../../../styledComponents/timesheet';
import { getHours, toHHMMSS } from '../../../utils/helper';
import { getRandomColor } from 'utils/getRandomColor';

const getTimes = date => {
  const times = [];
  let startDate = moment(date).startOf('isoWeek');
  while (moment(startDate).isSameOrBefore(moment(date).endOf('isoWeek'))) {
    times.push({
      date: startDate,
      hours: '0 h 00 m',
    });
    startDate = moment(startDate).add(1, 'days');
  }
  return times;
};

const WeeklyMemberView = ({
  selectedDate,
  selectedMember,
  profileData,

  timesheetsList,
  getTimesheetDetails,
  detailsUserData,
  setDetailsUserData,
  activeDate,
  setActiveDate,
  selectedPanelDate,
  setSelectedPanelDate,
  isDetailsOpen,
}) => {
  const [timesheetsData, setTimesheetsData] = useState(null);

  const [bgColors, setBgColors] = useState({});

  useEffect(() => {
    const times = getTimes(selectedDate);
    const durations = [];
    if (timesheetsList && timesheetsList.length > 0) {
      timesheetsList.forEach(timesheet => {
        durations.push(timesheet.duration);
        times.some(item => {
          if (moment(item.date).format('YYYY-MM-DD') === timesheet.date) {
            item.hours = getHours(toHHMMSS(timesheet.duration));
            return true;
          }
          return false;
        });
        if (
          detailsUserData &&
          detailsUserData.id === timesheet.user_id &&
          detailsUserData.date &&
          moment(detailsUserData.date).format('YYYY-MM-DD') === timesheet.date
        ) {
          setDetailsUserData({
            ...detailsUserData,
            totalTime: getHours(toHHMMSS(timesheet.duration)),
          });
        }
      });
      setTimesheetsData({
        durations,
        timesheets: [...times],
      });
    } else if (timesheetsList && timesheetsList.length === 0) {
      setTimesheetsData({
        durations,
        timesheets: times,
      });
    }
  }, [timesheetsList]);

  useEffect(() => {
    setActiveDate(selectedPanelDate);
  }, [selectedPanelDate]);

  return (
    <>
      <TotalTimeContainer>
        <TimeRangeText>{`${moment(selectedDate)
          .startOf('isoWeek')
          .format('MMMM DD')} - ${moment(selectedDate)
          .endOf('isoWeek')
          .format('MMMM DD')}`}</TimeRangeText>
      </TotalTimeContainer>
      <TableContainer>
        <TableHeadContainer>
          {timesheetsData &&
            timesheetsData.timesheets &&
            timesheetsData.timesheets.length > 0 &&
            timesheetsData.timesheets.map((time, index) => (
              <TableHeadItem key={index}>
                {moment(time.date).format('ddd')}
              </TableHeadItem>
            ))}
          <TableHeadItem total>Total</TableHeadItem>
        </TableHeadContainer>
        <TableBodyContainer>
          {timesheetsData &&
            timesheetsData.timesheets &&
            timesheetsData.timesheets.length > 0 &&
            timesheetsData.timesheets.map((time, index) => {
              if (!bgColors[time.date]) bgColors[time.date] = getRandomColor();
              return (
                <TableBodyItem key={index}>
                  {time.hours === '0 h 00 m' ? (
                    <DailyTime 
                      backgroundColor={bgColors[time.date]}
                      isActive={activeDate===(moment((time.date)).format('YYYY-MM-DD')) && isDetailsOpen}
                    >
                      -
                    </DailyTime>
                  ) : (
                    <DailyTime
                      backgroundColor={bgColors[time.date]}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        getTimesheetDetails(
                          time.date,
                          selectedMember
                            ? selectedMember.id
                            : localStorage.getItem('user_id'),
                        );
                        setDetailsUserData({
                          id: selectedMember
                            ? selectedMember.id
                            : localStorage.getItem('user_id'),
                          name: selectedMember
                            ? selectedMember.name
                            : profileData && profileData.id
                            ? `${profileData.first_name}${
                                profileData.last_name
                                  ? ` ${profileData.last_name}`
                                  : ''
                              }`
                            : null,
                          avatar: selectedMember
                            ? selectedMember.avatar
                              ? selectedMember.avatar
                              : avatar5
                            : profileData && profileData.thumbnail
                            ? profileData.thumbnail
                            : avatar5,
                          color: selectedMember
                          ? selectedMember.color
                          : profileData && profileData.color
                          ? profileData.color
                          : null,
                          date: time.date,
                          totalTime: time.hours,
                        });

                        setSelectedPanelDate(moment((time.date)).format('YYYY-MM-DD'));
                      }}
                      isActive={activeDate===(moment((time.date)).format('YYYY-MM-DD')) && isDetailsOpen}
                      largerZ={isDetailsOpen}
                    >
                      {time.hours}
                    </DailyTime>
                  )}
                  <TimesheetDate today={moment().isSame(time.date, "day")}>
                    {moment(time.date).format('MMM DD')}
                  </TimesheetDate>
                </TableBodyItem>
              );
            })}
          <TableBodyItem>
            <WeeklyTotal>
              {timesheetsData &&
              timesheetsData.durations &&
              timesheetsData.durations.length > 0
                ? getHours(
                    toHHMMSS(
                      timesheetsData.durations
                        .slice(1)
                        .reduce(
                          (prev, cur) => cur + prev,
                          timesheetsData.durations[0],
                        ),
                    ),
                  )
                : '0 h 00 m'}
            </WeeklyTotal>
          </TableBodyItem>
        </TableBodyContainer>
      </TableContainer>
    </>
  );
};

export default WeeklyMemberView;
