import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useLoadScript, // useJsApiLoader,
} from '@react-google-maps/api';
import { CommImageNameComp } from 'custom_modules/Members/CommonComponents';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import avatar5 from '../../assets/img/avatar_5.svg';
import call from '../../assets/img/icons/call.svg';
import { CommonFlex, CommonText } from '../../styledComponents/common';
import './styles.css';

const MarkerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  /* width: 200px; */
  height: max-content;
  gap: 10px;
  margin: 10px;
  max-height: 320px;
  overflow-y: auto;
`;

const ImageAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const PhoneNumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const LastSeenContainer = styled.div`
  display: flex;
  align-items: center;
`;

const options = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  disableDoubleClickZoom: true,
  gestureHandling: 'cooperative',
  mapId: process.env.REACT_APP_JOB_SITES_MAP_ID,
};

const containerStyle = {
  width: '100%',
  height: '85vh',
  borderLeft: '1px solid #e4e7eb',
  borderRadius: '10px',
};

const center = {
  lat: 21.424090674479555,
  lng: 39.822141261515604,
};

const circleOptions = {
  strokeColor: '#6F7AFF',
  strokeWeight: 2,
  fillColor: '#6F7AFF',
  fillOpacity: 0.2,
};

function Map({ userList, isAwayUsers, userCurrentLocation }) {
  const { t } = useTranslation();

  const [markers, setMarkers] = useState([]);
  const [activeMarkerIds, setActiveMarkerIds] = useState([]);

  const [maxZoom, setMaxZoom] = useState(22);

  const { isLoaded, loadError } = useLoadScript({
    id: 'live-location-map',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    mapIds: [process.env.REACT_APP_JOB_SITES_MAP_ID],
  });

  const [map, setMap] = useState(null);

  useMemo(() => {
    if (map && userCurrentLocation) {
      map.panTo(userCurrentLocation);
      map.setZoom(11);
    }
  }, [map, userCurrentLocation]);

  useEffect(() => {
    if (map) {
      map.addListener('zoom_changed', () => {
        setActiveMarkerIds([]);
      });
    }
  }, [map]);
  useEffect(() => {
    setActiveMarkerIds([]);
  }, [isAwayUsers]);

  useMemo(() => {
    if (map) {
      if (userList && userList.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        userList.forEach(user => {
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(user.latest_location.lat),
              parseFloat(user.latest_location.long),
            ),
          );
        });
        map.fitBounds(bounds);
      }
    }
  }, [userList]);

  useEffect(() => {
    // populate markers
    const markers = userList.map(user => {
      const marker = {
        id: user?.employee?.id,
        location: {
          lat: parseFloat(user?.latest_location?.lat),
          lng: parseFloat(user?.latest_location?.long),
        },
        icon: user?.employee?.avatar
          ? {
              url: user.employee.avatar + '#custom_marker',
              scaledSize: new google.maps.Size(32, 32),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(16, 16),
            }
          : avatar5,
        name: `${user?.employee?.first_name} ${user?.employee?.last_name}`,
        phone: user?.employee?.phone,
        office: user?.employee?.office,
        lastSeen: (user?.latest_location?.timestamp ?? 0) * 1000,
        color: user?.color,
      };
      return marker;
    });
    setMarkers(markers);
  }, [userList]);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = (markerId, markerLocation) => {
    const sameLocationMarkers = markers.filter(
      marker =>
        marker.location.lat === markerLocation.lat &&
        marker.location.lng === markerLocation.lng,
    );
    if (map.getZoom() === maxZoom) {
      setActiveMarkerIds(sameLocationMarkers.map(marker => marker.id));
    } else if (markerId) {
      setActiveMarkerIds([markerId]);
    }
  };

  const handleClusterClick = cluster => {
    if (map.getZoom() < maxZoom) {
      map.panTo(cluster.getCenter());
      return;
    }

    const clickedMarkers = cluster.getMarkers(); // Retrieves Google Maps Marker objects
    const includedMarkers = [];
    const markerDetails = clickedMarkers.map(clickedMarker => {
      const position = clickedMarker.getPosition(); // Get the marker's position (lat/lng)
      const lat = position.lat();
      const lng = position.lng();
      const marker = markers.find(
        marker =>
          marker.location.lat === lat &&
          marker.location.lng === lng &&
          !includedMarkers.includes(marker.id),
      );
      includedMarkers.push(marker.id);
      return marker;
    });

    setActiveMarkerIds(markerDetails.map(marker => marker.id));
  };

  if (loadError) {
    return <div>Map cannot be loaded right now! Please try again later</div>;
  }
  if (!isLoaded) return null;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={{
        ...options,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
      }}
      center={center}
      zoom={8}
      onLoad={map => setMap(map)}
      onUnmount={onUnmount}
    >
      <MarkerClusterer
        options={{
          averageCenter: true,
          minimumClusterSize: 5,
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          zoomOnClick: true,
          gridSize: 75,
          imageExtension: 'png',
          imageSizes: [53, 56, 66, 78, 90],
        }}
        onClick={handleClusterClick}
      >
        {clusterer =>
          markers.map((marker, index) => {
            return (
              <Marker
                key={marker.id}
                icon={marker.icon}
                position={marker.location}
                radius="16px"
                clusterer={clusterer}
                onClick={() => handleMarkerClick(marker.id, marker.location)}
              >
                {activeMarkerIds.includes(marker.id) &&
                  activeMarkerIds[0] === marker.id && (
                    <InfoWindow
                      position={marker.location}
                      onCloseClick={() => setActiveMarkerIds([])}
                    >
                      <div
                        style={{
                          paddingTop: '20px',
                          paddingBottom: '10px',
                        }}
                      >
                        <MarkerInfoContainer>
                          {activeMarkerIds.length > 1 ? (
                            activeMarkerIds.map((id, idx) => {
                              const activeMarker = markers.find(
                                marker => marker.id === id,
                              );
                              return (
                                <div key={activeMarker.id}>
                                  <CommImageNameComp
                                    imgSource={activeMarker.icon.url || avatar5}
                                    name={activeMarker.name}
                                    size="36px"
                                    fontSize="13px"
                                    index={index}
                                    color={activeMarker.color}
                                  />
                                  {activeMarker.office && (
                                    <CommonFlex gap="12px">
                                      <CommonText $label>
                                        {t('office')}
                                      </CommonText>
                                      <CommonText name>
                                        {activeMarker.office}
                                      </CommonText>
                                    </CommonFlex>
                                  )}
                                  {activeMarker.phone &&
                                    activeMarker.phone !== 'null' && (
                                      <PhoneNumberContainer>
                                        <img
                                          src={call}
                                          alt="call"
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                            marginRight: '10px',
                                          }}
                                        />
                                        <div>
                                          {activeMarker.phone || 'Not found'}
                                        </div>
                                      </PhoneNumberContainer>
                                    )}
                                  {isAwayUsers && (
                                    <LastSeenContainer>
                                      <div>Last seen: </div>
                                      <div style={{ marginLeft: '5px' }}>
                                        {moment(activeMarker.lastSeen).format(
                                          'Do MMM, HH:mm',
                                        )}
                                      </div>
                                    </LastSeenContainer>
                                  )}
                                  {idx < activeMarkerIds.length - 1 && (
                                    <hr
                                      style={{
                                        width: '100%',
                                        marginBottom: '2px',
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              <CommImageNameComp
                                imgSource={marker.icon.url || avatar5}
                                name={marker.name}
                                size="36px"
                                fontSize="13px"
                                index={index}
                                color={marker.color}
                              />
                              {marker.office && (
                                <CommonFlex gap="12px">
                                  <CommonText $label>{t('office')}</CommonText>
                                  <CommonText name>{marker.office}</CommonText>
                                </CommonFlex>
                              )}
                              {marker.phone && marker.phone !== 'null' && (
                                <PhoneNumberContainer>
                                  <img
                                    src={call}
                                    alt="call"
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      marginRight: '10px',
                                    }}
                                  />
                                  <div>{marker.phone || 'Not found'}</div>
                                </PhoneNumberContainer>
                              )}
                              {isAwayUsers && (
                                <LastSeenContainer>
                                  <div>Last seen: </div>
                                  <div style={{ marginLeft: '5px' }}>
                                    {moment(marker.lastSeen).format(
                                      'Do MMM, HH:mm',
                                    )}
                                  </div>
                                </LastSeenContainer>
                              )}
                            </div>
                          )}
                        </MarkerInfoContainer>
                      </div>
                    </InfoWindow>
                  )}
              </Marker>
            );
          })
        }
      </MarkerClusterer>
    </GoogleMap>
  );
}

export default Map;
