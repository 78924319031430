//Effects
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  DELETE_JOB,
  DELETE_JOB_FAILURE,
  DELETE_JOB_SUCCESS,
  GET_JOBS_LIST,
  GET_JOBS_LIST_FAILURE,
  GET_JOBS_LIST_SUCCESS,
  GET_JOBS_SHORT_LIST,
  GET_JOBS_SHORT_LIST_FAILURE,
  GET_JOBS_SHORT_LIST_SUCCESS,
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_FAILURE,
  GET_JOB_DETAILS_SUCCESS,
  JOB_ASSIGNEE_ADD,
  JOB_ASSIGNEE_ADD_FAILURE,
  JOB_ASSIGNEE_ADD_SUCCESS,
  JOB_ASSIGNEE_REMOVE,
  JOB_ASSIGNEE_REMOVE_FAILURE,
  JOB_ASSIGNEE_REMOVE_SUCCESS,
  UPDATE_JOB_STATUS,
  UPDATE_JOB_STATUS_FAILURE,
  UPDATE_JOB_STATUS_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';

export function* handleGetjobsList({ payload }) {
  try {
    const {
      organization_id,
      date,
      page,
      job_name,
      work_order_id,
      assignee_id,
      status,
      approval_status,
    } = payload;
    let url = `${organization_id}/workorder/jobs/all/?required_start_date=${
      date ? `${date}&required_end_date=${date}T23:59:59` : ''
    }${page ? `&page=${page}` : ''}${job_name ? `&name=${job_name}` : ''}${
      work_order_id ? `&work_order_id=${work_order_id}` : ''
    }${assignee_id ? `&assignees=${assignee_id}` : ''}${
      status ? `&status=${status}` : ''
    }${approval_status ? `&approval_status=${approval_status}` : ''}`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_JOBS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_JOBS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* handleJobApproval({ payload }) {
  const { organization_id, job_id, approval_id, details } = payload;
  try {
    const url = `${organization_id}/jobs/${job_id}/approval/${approval_id}/`;
    const method = 'PUT';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_JOB_STATUS_SUCCESS,
      payload: { ...payload, data: response, job_id },
    });
  } catch (err) {
    yield put({
      type: UPDATE_JOB_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetjobsShortList({ payload }) {
  try {
    const { organization_id, type } = payload;
    let url = `${organization_id}/workorder/jobs/all/short/${
      type === 'scheduled'
        ? `?status=ready to dispatch`
        : type === 'unscheduled'
        ? `?status=unscheduled`
        : ``
    }`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_JOBS_SHORT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_JOBS_SHORT_LIST_FAILURE,
      payload: err,
    });
  }
}
export function* handleGetJobDetails({ payload }) {
  try {
    const { organization_id, workOrder_id, job_id } = payload;
    const url = `${organization_id}/workorder/${workOrder_id}/job_with_schedule/${job_id}`;
    const method = 'GET';
    const response = yield defaultApi(url, method);

    yield put({
      type: GET_JOB_DETAILS_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_JOB_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* removeJobAssigneeRequest({ payload }) {
  try {
    let { organization_id, workOrder_id, job_id, user_id } = payload;
    let url = `${organization_id}/workorder/${workOrder_id}/jobs/${job_id}/assignees/${user_id}`;
    let method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: JOB_ASSIGNEE_REMOVE_SUCCESS,
      payload: {
        data: response,
        notify: 'DELETE',
      },
      userData: { userId: user_id },
    });
  } catch (err) {
    yield put({
      type: JOB_ASSIGNEE_REMOVE_FAILURE,
      payload: err,
    });
  }
}

export function* addJobAssigneeRequest({ payload }) {
  try {
    let { organization_id, workOrder_id, job_id, ...user_id } = payload;
    let url = `${organization_id}/workorder/${workOrder_id}/jobs/${job_id}/assignees/`;
    let method = 'POST';
    const response = yield defaultApi(url, method, user_id);

    yield put({
      type: JOB_ASSIGNEE_ADD_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: JOB_ASSIGNEE_ADD_FAILURE,
      payload: err,
    });
  }
}

export function* handleDeleteJob({ payload }) {
  try {
    const { organization_id, workOrder_id, job_id } = payload;
    let url = `${organization_id}/workorder/${workOrder_id}/jobs/${job_id}/`;
    let method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_JOB_SUCCESS,
      payload: { ...payload, data: response, job_id, notify: method },
    });
  } catch (err) {
    yield put({
      type: DELETE_JOB_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_JOBS_LIST, handleGetjobsList),
    takeLatest(GET_JOBS_SHORT_LIST, handleGetjobsShortList),
    takeLatest(GET_JOB_DETAILS, handleGetJobDetails),
    takeLatest(JOB_ASSIGNEE_REMOVE, removeJobAssigneeRequest),
    takeLatest(JOB_ASSIGNEE_ADD, addJobAssigneeRequest),
    takeLatest(DELETE_JOB, handleDeleteJob),
    takeLatest(UPDATE_JOB_STATUS, handleJobApproval),
  ]);
}
