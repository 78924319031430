import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar5 from '../../assets/img/avatar_5.svg';
import editIcon from '../../assets/img/icons/edit-pen.svg';
import createProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import ProjectListPopup from '../../components/ProjectListPopup/ProjectListPopup';
import Select from '../../components/ReactSelectDropdown';
import TaskActionTooltip from '../../components/Tooltip/TaskActionTooltip';
import {
  AssigneeMoreText,
  ColoredText,
  CommonFlex,
  CommonImage,
  CommonText,
  Container,
  ResendInvite,
  RoundLetterSection,
  StyledLink,
  TableText,
} from '../../styledComponents/common';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../../styledComponents/invoice';
import {
  InviteHeadSection,
  InviteLink,
  InviteProjectSection,
  InviteTableContainer,
  MemberNameDiv,
  MembersTableContainer,
  MembersTableHeadContainer,
  RadioButton,
  TeamSection,
  UserSettings,
} from '../../styledComponents/members';
import { AssignedProjectBox, TableItem } from '../../styledComponents/teams';
import { currencySymbolMap } from '../../utils/currencies';
import { RoundIcon } from '../TasksNew/tasksStyles';
import {
  ApprovalSettingsSection,
  CommFirstRoundLetterComp,
  CommImageInviteComp,
  CommImageNameComp,
} from './CommonComponents';

const MembersTable = ({
  selectedOrganization,
  history,
  membersList,
  isLoading,
  updateMemberwiseTimesheetApprovalSettings,
  invitedMembersList,
  activeTab,
  inviteMember,
  searchTerm,
  toggleInvitationDeleteModal,
}) => {
  const { t } = useTranslation();
  const [role, setRole] = useState(null);
  const inviteActionOptions = [
    { value: 'resend', label: 'Resend Invitation' },
    { value: 'delete', label: 'Delete Invitation' },
  ];
  useEffect(() => {
    if (selectedOrganization?.role) {
      setRole(selectedOrganization.role);
    }
  }, [selectedOrganization]);
  const onInviteActionChange = (e, invitation) => {
    if (e.value === 'delete') {
      toggleInvitationDeleteModal(invitation.id);
    } else if (e.value === 'resend') {
      resendMemberInvitation(invitation);
    }
  };
  const updateTimesheetApprovalSettings = (memberId, value) => {
    if (selectedOrganization?.id && memberId) {
      const payload = {
        organization_id: selectedOrganization.id,
        settings_id: memberId,
        require_timesheet_approval: value,
      };
      updateMemberwiseTimesheetApprovalSettings(payload);
    }
  };
  const resendMemberInvitation = invitation => {
    if (invitation) {
      // let projectList = [];
      // if (
      //   invitation.assignedProjects &&
      //   invitation.assignedProjects.length > 0
      // ) {
      //   invitation.assignedProjects.map(project => {
      //     projectList.push(project.id);
      //   });
      // }
      if (selectedOrganization?.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          email: invitation.email,
          // project_ids: projectList,
          resend: true,
        };
        inviteMember(payload);
      }
    }
  };

  useEffect(() => {
    console.log(invitedMembersList);
  }, [invitedMembersList]);


  return (
    <Container style={{ paddingBottom: `20px` }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : activeTab === 'active' ? (
        <>
          {membersList && membersList.length > 0 && (
            <MembersTableHeadContainer
              style={{ borderBottom: `1px solid #C2CCE1` }}
            >
              <TableItem>
                <TableText>{t('member_name')}</TableText>
              </TableItem>
              <TableItem>
                <TableText>{t('email_phone')}</TableText>
              </TableItem>
              <TableItem>
                <TableText>{t('role')}</TableText>
              </TableItem>
              <TableItem>
                <TableText>{t('payment')}</TableText>
              </TableItem>
              {role && role !== 'member' ? (
                <TableItem>
                  <TableText>{t('actions')}</TableText>
                </TableItem>
              ) : null}
            </MembersTableHeadContainer>
          )}
          {membersList && membersList.length > 0 ? (
            membersList.map((member, index) => (
              <MembersTableContainer key={index}>
                <TableItem>
                  <MemberNameDiv
                    onClick={() =>
                      history.push(`/user/member-details?id=${member.user.id}`)
                    }
                  >
                    <CommImageNameComp
                      imgSource={
                        member.user.avatar ? member.user.avatar : Avatar5
                      }
                      name={`${member.user.first_name} ${member.user.last_name}`}
                      size="36px"
                      fontSize="13px"
                      index={index}
                      color={member.user.color}
                      hoverUnderline
                    />
                  </MemberNameDiv>
                </TableItem>
                <TableItem
                  fontSize="12.5px"
                  style={{ overflowWrap: `anywhere` }}
                >
                  <CommonText fontSize="12.5px">
                    {member.user.email || `-`}
                  </CommonText>
                  <CommonText fontSize="12.5px">
                    {member.user.phone && member.user.phone !== 'null'
                      ? member.user.phone
                      : ` `}
                  </CommonText>
                </TableItem>
                <TableItem>
                  {member.role
                    ? member.role.charAt(0).toUpperCase() +
                      member.role.slice(1).toLowerCase()
                    : `-`}
                </TableItem>
                <TableItem>
                  <TableText fontSize="12.5px" name>
                    {(!member.payment || (member.payment?.pay_rate===0 && member.payment?.bill_rate===0))
                      ? 'No pay/bill rate set'
                      : `${currencySymbolMap[member.payment.currency]} ${(
                          member.payment.pay_rate / 100
                        ).toFixed(2)} / hour`}
                  </TableText>
                  {role !== 'member' && (!member.payment || (member.payment?.pay_rate===0 && member.payment?.bill_rate===0)) && (
                    <ResendInvite
                      margin="3px 0 0 0"
                      onClick={() =>
                        history.push(
                          `/user/member-details?id=${member.user.id}`,
                        )
                      }
                    >
                      Set Pay Rate
                    </ResendInvite>
                  )}
                  <ApprovalSettingsSection
                    memberId={member.id}
                    active={member.require_timesheet_approval}
                    handleChange={updateTimesheetApprovalSettings}
                    isAdmin={
                      selectedOrganization &&
                      selectedOrganization.role !== 'member'
                    }
                    fontSize="12.5px"
                  />
                </TableItem>

                {role === 'owner' ||
                (role === 'admin' && member.role !== 'owner') ? (
                  <TableItem>
                    <RoundIcon
                      type="edit"
                      onClick={() =>
                        history.push(
                          `/user/member-details?id=${member.user.id}`,
                        )
                      }
                    >
                      <CommonImage src={editIcon} alt="" size="16px" />
                      <TaskActionTooltip toolTipText={t('edit_member')} />
                    </RoundIcon>
                  </TableItem>
                ) : (
                  <></>
                )}
              </MembersTableContainer>
            ))
          ) : (
            <NoDataComponent
              title="No Members Found!"
              padding="5vh 0"
              imageHeight="140px"
            />
          )}
        </>
      ) : (
        <>
          {invitedMembersList && invitedMembersList.length > 0 && (
            <>
              <InviteHeadSection>
                <TableItem>
                  <TableText>Member</TableText>
                </TableItem>
                {/* <TableItem>
                  <TableText>Projects</TableText>
                </TableItem> */}
                <TableItem>
                  <TableText>Actions</TableText>
                </TableItem>
              </InviteHeadSection>
              {invitedMembersList.map((member, index) => (
                <InviteTableContainer key={index}>
                  <TableItem>
                    <CommImageInviteComp
                      imgSource={Avatar5}
                      member={member}
                      index={index}
                      size="36px"
                      resendInvite={resendMemberInvitation}
                    />
                  </TableItem>
                  {/* <TableItem>
                    <InviteProjectSection>
                      {member.assignedProjects &&
                        member.assignedProjects.length > 0 &&
                        member.assignedProjects.map((project, index) => (
                          <AssignedProjectBox key={index}>
                            {project.name.length < 12
                              ? project.name
                              : `${project.name.slice(0, 12)}...`}
                          </AssignedProjectBox>
                        ))}
                      {member.assignedProjects &&
                        member.assignedProjects.length === 0 && (
                          <TableText name>No projects</TableText>
                        )}
                    </InviteProjectSection>
                  </TableItem> */}
                  <TableItem style={{ margin: `auto 0` }}>
                    <Select
                      isSearchable={false}
                      value={null}
                      options={inviteActionOptions}
                      placeholder="Actions"
                      onChange={e => {
                        onInviteActionChange(e, member);
                      }}
                      styles={createProjectDropdown(null)}
                    />
                  </TableItem>
                </InviteTableContainer>
              ))}
            </>
          )}
          {invitedMembersList &&
            invitedMembersList.length === 0 &&
            !isLoading && (
              <div
                style={{
                  padding: '40px 0px 20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!searchTerm ? (
                  <span>
                    You don't have invited members.{' '}
                    <InviteLink
                      onClick={() => history.push('/user/member-invitation')}
                    >
                      Click here to invite one.
                    </InviteLink>
                  </span>
                ) : (
                  `No member found with this email.`
                )}
              </div>
            )}
        </>
      )}
    </Container>
  );
};

export default MembersTable;
