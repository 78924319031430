import moment from 'moment';
import { useState } from 'react';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';

import datePickerIcon from '../../assets/img/icons/date_picker_icon.svg';

const CustomInputIcon = ({ customClassName }) => {
  return  <img 
            src={datePickerIcon} 
            style={{ 
              marginTop: customClassName ? "-8px" : "-2px", 
              height: customClassName ? "14px" : "20px",
              width: customClassName ? "14px" : "20px"
            }} 
          />;
};

const DatePicker = ({
  width,
  date,
  onDateChange,
  dateDisplayFormat,
  placeholder,
  allowPreviousDates,
  disableFutureDates,
  disableCurrentAndFutureDates,
  disablePreviousDatesFrom,
  disabled,
  error,
  customClassName = null
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={error ? `error ${customClassName || ''}` : customClassName || null}
      onClick={() => {
        setFocused(true);
      }}
    >
      <SingleDatePicker
        numberOfMonths={1}
        onDateChange={date => onDateChange(date)}
        onFocusChange={({ focused }) => setFocused(focused)}
        focused={focused}
        date={date}
        displayFormat={dateDisplayFormat}
        showDefaultInputIcon={false}
        customInputIcon={<CustomInputIcon customClassName={customClassName} />}
        inputIconPosition="after"
        hideKeyboardShortcutsPanel
        placeholder={placeholder}
        disabled={disabled ? true : false}
        readOnly
        isOutsideRange={
          disableFutureDates
            ? day => !isInclusivelyBeforeDay(day, moment())
            : allowPreviousDates
              ? () => false
              : disableCurrentAndFutureDates
                ? day => !isInclusivelyBeforeDay(day, moment().subtract(1, 'day'))
                : disablePreviousDatesFrom
                  ? day => day.isBefore(moment(disablePreviousDatesFrom))
                  : day => {
                    if (day.isSameOrBefore(moment().subtract(1, 'day'))) {
                      return true;
                    }
                    return false;
                  }
        }
      />
    </div>
  );
};

export default DatePicker;
