import { connect } from 'react-redux';

// Component
import Clients from './Clients';
// Actions
import {
  createClient,
  createCustomerBulk,
  deleteClient,
  getClientList,
  getClientLocation,
  getGHLContactList,
  updateClient,
} from './clientsActions';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  clientList: state.client.clientList,
  isLoading: state.client.isLoading,
  clientUpdateLoading: state.client.clientUpdateLoading,
  updatingClientId: state.client.updatingClientId,
  clientDeleteLoading: state.client.clientDeleteLoading,
  deletingClientId: state.client.deletingClientId,
  bulkCustomerCreated: state.client.bulkCustomerCreated,
  isBulkCustomerLoading: state.client.isBulkCustomerLoading,
  //pagination
  totalClientsCount: state.client.totalClientsCount,
  pageSize: state.client.pageSize,
  reloadClientList: state.client.reloadClientList,
  //ghl contact
  ghlSyncContactIsLoading: state.client.ghlSyncContactIsLoading,
  ghlContactList: state.client.ghlContactList,

  filters: state.filterReducer.filters,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getClientList: details => dispatch(getClientList({ ...details })),
  getGHLContactList: details => dispatch(getGHLContactList({ ...details })),
  createClient: details => dispatch(createClient({ ...details })),
  createCustomerBulk: details => dispatch(createCustomerBulk({ ...details })),
  updateClient: details => dispatch(updateClient({ ...details })),
  deleteClient: details => dispatch(deleteClient({ ...details })),
  getClientLocation: details => dispatch(getClientLocation({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
