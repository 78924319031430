import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../../../assets/img/avatar_5.svg';
import checkedIn from '../../../../assets/img/icons/checked-in.svg';
import checkedOut from '../../../../assets/img/icons/checked-out.svg';
import clockedIn from '../../../../assets/img/icons/clocked-in.svg';
import clockedOut from '../../../../assets/img/icons/clocked-out.svg';
import tick from '../../../../assets/img/icons/tick-white-bold.svg';
import { CardText } from '../../../../components/DashboardCards/cardStyles';
import {
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
} from '../../../../styledComponents/common';
import { CommImageNameComp } from '../../../Members/CommonComponents';
import {
  CardBlock,
  GreenCircle,
  InfoSectionCard,
  LateTag,
  TimelineBorder,
  TimelineContainer,
  TimelineInfoContainer,
  TimelineTextSection,
  UserActivityContainer,
  UserInfoAndTimelineContainer,
} from '../routeMapStyles';

const getEventDetails = event => {
  switch (event) {
    case 'clock_in':
      return { iconName: clockedIn, eventName: 'clocked_in' };
    case 'clock_out':
      return { iconName: clockedOut, eventName: 'clocked_out' };
    case 'checkin':
      return { iconName: checkedIn, eventName: 'checked_in' };
    case 'checkout':
      return { iconName: checkedOut, eventName: 'checked_out' };
    default:
      return null; // or some default case
  }
};

const SingleTimelineEvent = ({
  event,
  timestamp,
  lat,
  long,
  task,
  last,
  selectedDate,
  officeName,
}) => {
  const { t } = useTranslation();

  const { iconName, eventName } = useMemo(
    () => getEventDetails(event),
    [event],
  );

  if (!iconName || !eventName) return null;

  return (
    <TimelineContainer key={timestamp}>
      <CardText name fontSize="13px" title margin="5px 0 0 0">
        <CommonFlex direction="column" alignItems="start" gap="0px">
          {moment.unix(timestamp).format('h:mm A')}
          <CommonText fontSize="11px">
            {!moment.unix(timestamp).isSame(moment(selectedDate), 'day') && (
              <span> ({moment.unix(timestamp).format("DD MMM 'YY")})</span>
            )}
          </CommonText>
        </CommonFlex>
      </CardText>
      <TimelineInfoContainer>
        <CommonImage src={iconName} alt="" />
        <CardText fontSize="12px" name title>
          {t(eventName)}
        </CardText>
        <CommonGrid
          columns="2px 1fr"
          style={{
            width: '300px',
            marginLeft: '14px',
          }}
          gap="21px"
        >
          {!last ? (
            <TimelineBorder />
          ) : (
            <div
              style={{
                width: '2px',
              }}
            />
          )}
          <div>
            {officeName && (
              <CommonText
                fontSize="12px"
                name
                fontWeight="500"
                style={{
                  width: '100%',
                  maxWidth: '250px',
                }}
              >
                {`${officeName}`}
              </CommonText>
            )}
            {task?.id && (
              <TimelineTextSection>
                <CardText fontSize="12px" name fontWeight="500">
                  {`${t('task')}: ${task.name}`}
                </CardText>
                {task.location && task.location.id && (
                  <CardText fontSize="13px" label fontWeight="500">
                    {task.location.address}
                  </CardText>
                )}
              </TimelineTextSection>
            )}
            {!task && !officeName && <div style={{ height: '20px' }} />}
          </div>
        </CommonGrid>
      </TimelineInfoContainer>
    </TimelineContainer>
  );
};

const Timeline = ({ eventsList, selectedDate, selectedMember }) => {
  return (
    <UserInfoAndTimelineContainer>
      <UserActivityContainer>
        {selectedMember?.id ? (
          <CommImageNameComp
            imgSource={selectedMember?.avatar || avatar5}
            name={selectedMember.name}
            size="60px"
            fontSize="13px"
            index={0}
            color={selectedMember.color}
          />
        ) : null}

        <CommonText>
          {moment(selectedDate).format('dddd, DD MMMM, YYYY')}
        </CommonText>
      </UserActivityContainer>
      {eventsList.map((event, index) => {
        return (
          <SingleTimelineEvent
            {...event}
            last={index === eventsList.length - 1}
            selectedDate={selectedDate}
          />
        );
      })}
      {/* {TimelineArray &&
        TimelineArray.length > 0 &&
        TimelineArray.map((e, index) => (
          <TimelineContainer key={index}>
            <CardText name fontSize="13px" title margin="5px 0 0 0">
              {e.time}
            </CardText>
            <TimelineInfoContainer>
              <img src={TimelineIcon(e.status)} alt="" width="100%" />
              <CardText fontSize="12px" color="#4AB37B" title>
                {e.status}
              </CardText>
              <TimelineBorder />
              <TimelineTextSection>
                <CardText fontSize="12px" name fontWeight="500">
                  {e.status === "Clocked in" || e.status === "Clocked Out"
                    ? `Office: ${e.office}`
                    : `Task: ${e.task}`}
                </CardText>
                <CardText fontSize="13px" label fontWeight="500">
                  {e.address}
                </CardText>
              </TimelineTextSection>
            </TimelineInfoContainer>
          </TimelineContainer>
        ))} */}
    </UserInfoAndTimelineContainer>
  );
};

export default Timeline;
