//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  REQUEST_USER_PROFILE,
  REQUEST_USER_PROFILE_SUCCESS,
  REQUEST_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  GET_USER_EMAILS,
  GET_USER_EMAILS_SUCCESS,
  GET_USER_EMAILS_FAILURE,
  ADD_EMAIL,
  ADD_EMAIL_SUCCESS,
  ADD_EMAIL_FAILURE,
  DELETE_EMAIL,
  DELETE_EMAIL_SUCCESS,
  MAKE_EMAIL_DEFAULT,
  MAKE_EMAIL_DEFAULT_SUCCESS,
  MAKE_EMAIL_DEFAULT_FAILURE,
  DELETE_EMAIL_FAILURE,
} from "../../modules/constants";

//Handle login request
export function* handleProfileRequest({ payload }) {
  try {
    const { organization_id, user_id, ...data } = payload;
    let url = ``;
    if (user_id) {
      url = `user_profile/${user_id}/`;
    } else {
      url = `user_profile/${localStorage.getItem("user_profile")}/`;
    }
    let method = data.method === "GET" ? "GET" : "PATCH";
    let details = new FormData();
    // eslint-disable-next-line
    Object.keys(data).map((name) => {
      if (name === "method") {
      } else {
        details.append(name, data[name]);
      }
    });

    const response = yield defaultApi(
      url,
      method,
      data.method === "GET" ? "" : details
    );

    yield put({
      type: REQUEST_USER_PROFILE_SUCCESS,
      payload: {
        data: {...response, user_id},
        notify: data.method === "GET" ? false : true,
        timezoneOffset: data.timezoneOffset
      },
    });
  } catch (err) {
    yield put({
      type: REQUEST_USER_PROFILE_FAILURE,
      payload: err,
    });
  }
}

export function* handleProfileUpdate({ payload }) {
  try {
    let url = `user_profile/${localStorage.getItem("user_profile")}/`;
    let method = "PATCH";
    let details = new FormData();
    // eslint-disable-next-line
    Object.keys(payload).map((name) => {
      details.append(name, payload[name]);
    });

    const response = yield defaultApi(url, method, details);

    yield put({
      type: UPDATE_USER_PROFILE_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (err) {
    yield put({
      type: UPDATE_USER_PROFILE_FAILURE,
      payload: err,
    });
  }
}

export function* handleProfileDelete({ payload }) {
  try {
    let url = `account/delete/`;
    let method = "POST";

    const response = yield defaultApi(url, method, payload);

    yield put({
      type: DELETE_ACCOUNT_SUCCESS,
      payload: {
        data: response,
        notify: "DELETE",
      },
    });
  } catch (err) {
    yield put({
      type: DELETE_ACCOUNT_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetUserEmailsRequest() {
  try {
    const url = `auth/user/emails/`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_USER_EMAILS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_USER_EMAILS_FAILURE,
      payload: err,
    });
  }
}

export function* handleAddEmailRequest({ payload }) {
  try {
    const url = `auth/user/emails/`;
    let method = "POST";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: ADD_EMAIL_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: ADD_EMAIL_FAILURE,
      payload: err,
    });
  }
}

export function* handleDeleteEmailRequest({ payload }) {
  try {
    const url = `auth/user/emails/${payload.id}/`;
    let method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_EMAIL_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: DELETE_EMAIL_FAILURE,
      payload: err,
    });
  }
}

export function* handleMakeEmailDefaultRequest({ payload }) {
  const details = {
    email: payload.email,
    default: true,
  };
  try {
    const url = `auth/user/emails/${payload.id}/`;
    let method = "PATCH";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: MAKE_EMAIL_DEFAULT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: MAKE_EMAIL_DEFAULT_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(REQUEST_USER_PROFILE, handleProfileRequest),
    takeLatest(UPDATE_USER_PROFILE, handleProfileUpdate),
    takeLatest(DELETE_ACCOUNT, handleProfileDelete),
    takeLatest(GET_USER_EMAILS, handleGetUserEmailsRequest),
    takeLatest(ADD_EMAIL, handleAddEmailRequest),
    takeLatest(DELETE_EMAIL, handleDeleteEmailRequest),
    takeLatest(MAKE_EMAIL_DEFAULT, handleMakeEmailDefaultRequest),
  ]);
}
