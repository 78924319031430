import { useEffect } from 'react';
import arrowIcon from '../../../assets/img/icons/arrow-right-black.svg';
import { CommonImage } from '../../../styledComponents/common';
import {
  BottomPaginationButtonsContainer,
  BottomPaginationContainer,
  BottomPaginationDot,
  PaginationButton,
} from '../paginationStyles';
import { DOTS, usePagination } from './usePagination';

const BottomPagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  useEffect(() => {
    if (currentPage > paginationRange[paginationRange.length - 1]) {
      onPageChange(paginationRange[paginationRange.length - 1]);
    }
  }, [totalCount]);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <BottomPaginationContainer>
      <BottomPaginationButtonsContainer>
        <PaginationButton
          onClick={onPrevious}
          previous
          disabled={currentPage === 1}
        >
          <CommonImage src={arrowIcon} alt="previous" />
        </PaginationButton>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <BottomPaginationDot key={index}>&#8230;</BottomPaginationDot>
            );
          }

          return (
            <PaginationButton
              key={index}
              selected={pageNumber === currentPage}
              onClick={() => {
                if (pageNumber !== currentPage) {
                  onPageChange(pageNumber);
                }
              }}
            >
              {pageNumber}
            </PaginationButton>
          );
        })}
        <PaginationButton onClick={onNext} disabled={currentPage === lastPage}>
          <CommonImage src={arrowIcon} alt="next" />
        </PaginationButton>
      </BottomPaginationButtonsContainer>
    </BottomPaginationContainer>
  );
};

export default BottomPagination;
