import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const MultiAudioPlayerModal = ({ isOpen, toggle, audioEvidences }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: audioEvidences.length > 6 ? '10vh' : '15vh',
      }}
    >
      <ModalBody style={{ padding: '25px', display: 'grid' }}>
        <div
          style={{
            maxHeight: '60vh',
            overflowY: 'auto',
          }}
        >
          {audioEvidences?.map((audio, index) => (
            <>
              <Button
                close
                onClick={toggle}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: '5px',
                }}
              >
                <span aria-hidden>&times;</span>
              </Button>
              <audio
                controls
                style={{ width: '100%', height: '70px', padding: '10px' }}
              >
                <source src={audio.audio} type="audio/mp4" />
              </audio>
            </>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MultiAudioPlayerModal;
