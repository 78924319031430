import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, ColoredButtonWithIcon } from 'styledComponents/buttons';

import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import PricingPlan from '../../../components/PricingPlan/PricingPlan';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import {
  CommonFlex,
  CommonText,
  Container,
  PageTitle,
} from '../../../styledComponents/common';
import { getDistanceFromCoords } from '../../../utils/helper';
import { DataAndMapContainer } from '../JobSites/jobSitesStyles';
import RouteMapFilter from './RouteMapFilter/filterContainer';
import RouteMapGoogleMap from './RouteMapGoogleMap/RouteMapGoogleMap';
import Timeline from './Timeline/timelineContainer';
import { MapAndAlertContainer, MapTopAlert } from './routeMapStyles';

const mapLibraries = ['geometry'];
const mapIds = [process.env.REACT_APP_JOB_SITES_MAP_ID];
const markerColors = ['#3887EE', '#ff4d4d', '#27ae60', '#7d70b3'];

const RouteMap = ({
  locationsIsLoading,
  selectedOrganization,

  // getRouteMapLocations,
  // getSingleEmployeeClockInOutList,
  // getTaskCheckInOutList,

  routeMapLocations,
  clockInOutList,
  taskCheckInOutList,

  getOfficesShortList,
  officesShortList,
  getOfficeDetails,
  officeDetails,

  profile,
  history,
}) => {
  const { t } = useTranslation();
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedOffice, setSelectedOffice] = useState(null);

  const [map, setMap] = useState(null);
  // const [linePoints, setLinePoints] = useState([]);
  const [markers, setMarkers] = useState([]);

  const [taskCheckInOutMarkers, setTaskCheckInOutMarkers] = useState([]);
  const [clockInOutMarkers, setClockInOutMarkers] = useState([]);
  const [routes, setRoutes] = useState([]);

  const [eventsList, setEventsList] = useState([]);

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);
  const [pricingPopup, setPricingPopup] = useState(false);

  // timeline panel
  const [timelineData, setTimelineData] = useState(null);
  const [timelineIsOpen, setTimelineIsOpen] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    id: 'route-map-google-maps-script-loader',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    libraries: mapLibraries,
    mapIds,
  });

  const buildMarkerIcon = (
    location,
    nextLocation,
    attendanceIndex,
    options = {},
  ) => {
    if (map) {
      const g = window.google.maps;
      let anchor;
      let fillColor =
        markerColors[attendanceIndex % markerColors.length] || markerColors[0];
      let scale = options.scale || 3;
      let fillOpacity = 1;

      const heading = g.geometry.spherical
        .computeHeading(location, nextLocation)
        .toFixed(1);

      return {
        // path,
        rotation: parseFloat(heading),
        scale,
        anchor,
        fillColor,
        fillOpacity,
        strokeColor: '#fff',
        strokeWeight: 1,
        strokeOpacity: 1,
      };
    }
    return null;
  };
  const parseLocation = location => ({
    lat: parseFloat(location.lat),
    lng: parseFloat(location.long),
  });
  const addLocation = (locations, location) => {
    const { lat, lng } = parseLocation(location);
    locations.push({ lat, lng });
  };
  const handleDirectionMarkers = (
    directionMarkers,
    data,
    attendanceIndex,
    index,
    remainingLocations,
    clockInTimestamp,
    clockOutTimestamp,
  ) => {
    if (index > 0 && index < remainingLocations.length - 1) {
      const lastMarkerLoc =
        directionMarkers.length > 0
          ? directionMarkers[directionMarkers.length - 1].location
          : null;
      if (
        !clockOutTimestamp ||
        (data.timestamp > clockInTimestamp &&
          data.timestamp < clockOutTimestamp)
      ) {
        if (lastMarkerLoc) {
          const dist = getDistanceFromCoords(lastMarkerLoc, data.location);
          if (dist > 150) {
            const nextLocation = parseLocation(remainingLocations[index + 1]);
            data.icon = buildMarkerIcon(
              data.location,
              nextLocation,
              attendanceIndex,
            );
            directionMarkers.push(data);
          }
        } else {
          const nextLocation = parseLocation(remainingLocations[index + 1]);
          directionMarkers.push({
            ...data,
            icon: buildMarkerIcon(data.location, nextLocation, attendanceIndex),
          });
        }
      }
    }
  };
  const handleLocations = (
    locations,
    data,
    removedIndices,
    index,
    remainingLocations,
    clockInTimestamp,
    clockOutTimestamp,
  ) => {
    if (locations.length > 0 && index < remainingLocations.length - 1) {
      const lastLoc = locations[locations.length - 1];
      const dist = getDistanceFromCoords(lastLoc, data.location);
      if (dist <= 15) {
        removedIndices.add(index);
        return;
      }
    }

    if (
      !clockOutTimestamp ||
      (data.timestamp > clockInTimestamp && data.timestamp < clockOutTimestamp)
    ) {
      locations.push(data.location);
      removedIndices.add(index);
    }
  };

  const calculateRoute = useMemo(() => {
    const processLocations = () => {
      const polylines = [];
      const directionMarkers = [];

      let remainingLocations = [...routeMapLocations];

      for (const [attendanceIndex, clockInOut] of clockInOutList.entries()) {
        const {
          clockin_timestamp,
          clockout_timestamp,
          clockin_lat,
          clockin_long,
          clockout_lat,
          clockout_long,
        } = clockInOut;
        if (!clockin_timestamp) {
          continue;
        }

        const locations = [];
        const removedIndices = new Set();

        addLocation(locations, { lat: clockin_lat, long: clockin_long });

        remainingLocations.forEach((location, index) => {
          const { id, timestamp } = location;
          const locationData = {
            id,
            location: parseLocation(location),
            timestamp,
          };

          handleDirectionMarkers(
            directionMarkers,
            locationData,
            attendanceIndex,
            index,
            remainingLocations,
            clockin_timestamp,
            clockout_timestamp,
          );
          handleLocations(
            locations,
            locationData,
            removedIndices,
            index,
            remainingLocations,
            clockin_timestamp,
            clockout_timestamp,
          );
        });

        if (clockout_timestamp) {
          addLocation(locations, { lat: clockout_lat, long: clockout_long });
        }

        remainingLocations = remainingLocations.filter(
          (_, index) => !removedIndices.has(index),
        );
        polylines.push({ points: locations });
      }
      return { polylines, directionMarkers };
    };

    return clockInOutList.length > 0 && routeMapLocations.length > 0
      ? processLocations()
      : [];
  }, [clockInOutList, routeMapLocations]);

  useEffect(() => {
    const { polylines, directionMarkers } = calculateRoute;
    setRoutes(polylines?.length > 0 ? polylines : []);
    setMarkers(directionMarkers?.length > 0 ? directionMarkers : []);
    // setRoutes(calculateRoute);
  }, [calculateRoute]);

  useEffect(() => {
    let events = [];

    // const taskMarkers =
    //   taskCheckInOutList?.map(event => ({
    //     location: parseLocation(event),
    //     timestamp: event.timestamp,
    //     event: event.event,
    //   })) || [];
    // if (taskMarkers.length > 0) {
    //   events = [...events, ...taskCheckInOutList];
    //   setTaskCheckInOutMarkers(taskMarkers);
    // } else {
    //   setTaskCheckInOutMarkers([]);
    // }
    const attendanceMarkers =
      clockInOutList?.reduce((markers, event) => {
        if (event.clockin_timestamp) {
          markers.push({
            officeName: event.office_name,
            location: {
              lat: parseFloat(event.clockin_lat),
              lng: parseFloat(event.clockin_long),
            },
            timestamp: event.clockin_timestamp,
            event: 'clock_in',
          });
        }
        if (event.clockout_timestamp) {
          markers.push({
            location: {
              lat: parseFloat(event.clockout_lat),
              lng: parseFloat(event.clockout_long),
            },
            timestamp: event.clockout_timestamp,
            event: 'clock_out',
          });
        }
        return markers;
      }, []) || [];
    setClockInOutMarkers(attendanceMarkers);

    if (attendanceMarkers.length > 0) {
      events = [...events, ...attendanceMarkers];
    }

    setEventsList(events.sort((a, b) => a.timestamp - b.timestamp));
  }, [taskCheckInOutList, clockInOutList]);

  // useEffect(() => {
  // }, [clockInOutList]);

  useEffect(() => {
    fetch('https://hutils.loxal.net/whois')
      .then(resp => resp.json())
      .then(res => {
        if (res && res.ip) {
          setUserCurrentLocation({
            lat: res.latitude,
            lng: res.longitude,
          });
        }
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    checkPlan();
  }, [selectedOrganization]);

  useEffect(() => {
    if (map && userCurrentLocation) {
      if (!routes || (routes && !routes.length)) {
        map.panTo(userCurrentLocation);
      }
    }
  }, [map, userCurrentLocation]);

  // useEffect(() => {
  //   if (routeMapLocations && routeMapLocations.length > 0) {
  //     // let totalDuration = 0;
  //     const userData = routeMapLocations[0].employee;
  //     const points = [];
  //     const markers = [];
  //     routeMapLocations.forEach((location, index) => {
  //       const data = {
  //         id: location.id,
  //         location: {
  //           lat: parseFloat(location.lat),
  //           lng: parseFloat(location.long),
  //         },
  //         timestamp: location.timestamp,
  //       };
  //       if (index === 0) {
  //         data.start = true;
  //         markers.push(data);
  //       } else if (index === routeMapLocations.length - 1) {
  //         data.end = true;
  //         markers.push(data);
  //       } else {
  //         const lastMarkerLoc = markers[markers.length - 1];
  //         const dist = getDistanceFromCoords(
  //           lastMarkerLoc.location,
  //           data.location,
  //         );
  //         if (dist > 150) {
  //           const nextLocation = {
  //             lat: parseFloat(routeMapLocations[index + 1].lat),
  //             lng: parseFloat(routeMapLocations[index + 1].long),
  //           };
  //           data.icon = buildMarkerIcon(data.location, nextLocation);
  //           markers.push(data);
  //         }
  //       }

  //       if (
  //         !points.length ||
  //         index === 0 ||
  //         index === routeMapLocations.length - 1
  //       ) {
  //         points.push(data.location);
  //       } else {
  //         const lastPointLoc = points[points.length - 1];
  //         const dist = getDistanceFromCoords(lastPointLoc, data.location);
  //         if (dist > 15) {
  //           points.push(data.location);
  //         }
  //       }
  //     });

  //     setLinePoints(points);
  //     setMarkers(markers);
  //   } else if (routeMapLocations && routeMapLocations.length === 0) {
  //     setLinePoints([]);
  //     setMarkers([]);
  //   }
  // }, [routeMapLocations]);

  const toggleTimeline = value => {
    setTimelineIsOpen(value);
    if (!value) {
      setTimelineData(null);
    }
  };

  const selectOffice = e => {
    if (e && e.value && selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        office_id: e.value,
      };

      getOfficeDetails(payload);
    }

    setSelectedMember('');
    // getDailyTimesheets(payload);
    setSelectedOffice(e);
  };

  const pricingPopupToggle = () => {
    setPricingPopup(!pricingPopup);
  };

  const checkPlan = () => {
    if (selectedOrganization?.plan?.name === 'Free') {
      setPricingPopup(true);
    }
  };

  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={timelineIsOpen}
        closePanel={() => toggleTimeline(false)}
        width="530px"
      >
        <Timeline
          eventsList={eventsList}
          selectedDate={selectedDate}
          selectedMember={selectedMember}
        />
      </RightSlidingPanel>
      <PricingPlan
        isOpen={pricingPopup}
        toggle={pricingPopupToggle}
        popupText="You don't have access to route map feature in your current plan."
        popupTitle="Upgrade to Standard or Elite to use this feature."
        history={history}
        backRoute="/user/dashboard"
      />
      <PageTitle>{t('route_map')}</PageTitle>
      <CommonFlex justifyContent="space-between" margin="30px 0 20px">
        <RouteMapFilter
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          selectedOffice={selectedOffice}
          setSelectedOffice={setSelectedOffice}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectOffice={selectOffice}
          getOfficesShortList={getOfficesShortList}
          officesShortList={officesShortList}
          officeDetails={officeDetails}
          profile={profile}
        />
        <ColoredButtonWithIcon
          style={{ alignSelf: 'center', marginTop: 'auto' }}
          onClick={() => toggleTimeline(true)}
        >
          {t('see_details')}
        </ColoredButtonWithIcon>
      </CommonFlex>

      <MapAndAlertContainer>
        {locationsIsLoading ? (
          <MapTopAlert fontSize="15px" height="48px">
            <ComponentCircleLoader padding="10px 0" size={16} color="#fff" />
          </MapTopAlert>
        ) : !routeMapLocations ||
          (routeMapLocations && !routeMapLocations.length) ? (
          <MapTopAlert fontSize="15px" height="48px">
            {t('There_is_no_location_data_for_this_user')}
          </MapTopAlert>
        ) : null}
        {loadError ? (
          <div>Map cannot be loaded right now! Please try again later</div>
        ) : isLoaded ? (
          <RouteMapGoogleMap
            map={map}
            setMap={setMap}
            // linePoints={linePoints}
            routes={routes}
            markers={markers}
            selectedTimesheet={null}
            taskMarkers={taskCheckInOutMarkers}
            clockInOutMarkers={clockInOutMarkers}
          />
        ) : (
          <ComponentCircleLoader />
        )}
      </MapAndAlertContainer>
    </div>
  );
};

export default RouteMap;
