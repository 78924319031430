import { connect } from "react-redux";

// Component
import UserProfile from "./UserProfile.jsx";

// Actions
import {
  profileOperation,
  profileCreate,
  profileDelete,
  getUserEmails,
  addEmail,
  deleteEmail,
  makeEmailDefault,
} from "./userProfileActions";
import { changePasswordAction } from "../ChangePassword/changePasswordActions";
import { loginProfileCreatedReset } from "../Login/loginActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  profile: state.profile,
  updateIsLoading: state.profile.updateIsLoading,
  profileDataError: state.profile.profileDataError,
  auth: state.auth,
  organizations: state.organization,
  redirect: state.profile.redirect,
  passwordChanged: state.resetPassword,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  profileOperation: (details) => dispatch(profileOperation({ ...details })),
  profileCreate: (details) => dispatch(profileCreate({ ...details })),
  profileDelete: (details) => dispatch(profileDelete(details)),
  changePasswordAction: (details) =>
    dispatch(changePasswordAction({ ...details })),
  getUserEmails: () => dispatch(getUserEmails()),
  addEmail: (details) => dispatch(addEmail({ ...details })),
  deleteEmail: (details) => dispatch(deleteEmail({ ...details })),
  makeEmailDefault: (details) => dispatch(makeEmailDefault({ ...details })),
  loginProfileCreatedReset: () => dispatch(loginProfileCreatedReset()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
