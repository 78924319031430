import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import noDataGraph from '../../../assets/img/common/no-data-graph.svg';
import Bean from '../../../assets/img/icons/delete.svg';
import duplicate from '../../../assets/img/icons/duplicate-new.svg';
import Plus from '../../../assets/img/icons/plus.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import FilterDropDownStyle from '../../../components/DropdownStyle/FilterDropDownStyle';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import LinearActivityBar from '../../../components/ProgressBar/LinearActivityBar';
import Select from '../../../components/ReactSelectDropdown';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import {
  FilterInnerSection,
  FilterSection,
} from '../../../styledComponents/Activity';
import {
  ActionSection,
  BudgetLeftSection,
  CardTextData,
  ChartContainer,
  ClientDeadlineGrid,
  DetailTitleSelectSection,
  GrayButton,
  InvoiceSectionContainer,
  InvoiceSectionTitle,
  InvoiceTableContainer,
  InvoiceTableItem,
  LinearProgressBarTrack,
  LinearProgressBarTrain,
  ListUpperSection,
  NoDataGraph,
  NoDataGraphContainer,
  NoDataText,
  ProjectCardLabel,
  ProjectCardText,
  ProjectItemCount,
  ProjectListSection,
  ProjectRightButtonContainer,
  ProjectTitleDiv,
  ViewLessCard,
  ViewLessCardContainer,
} from '../../../styledComponents/ProjectsNew';
import {
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import {
  CardTitle,
  CommonFlex,
  CommonText,
  Container,
  ContentHeader,
  DividerLine,
  FormAlert,
  InputField,
  InputLabel,
  InputSection,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
} from '../../../styledComponents/common';
import { RedStar, SingleSection } from '../../../styledComponents/members';
import {
  ButtonFlexContainer,
  PaddingBox,
  UpperTitleButtonSection,
} from '../../../styledComponents/members';
import { TooltipContainerWorkOrder } from '../../../styledComponents/workOrder';
import { colors } from '../../../styles/light/colors';
import { projectChartOptions } from '../../../utils/chartOptions';
import { currencySymbolMap } from '../../../utils/currencies';
import { checkHtml, checkUrl } from '../../../utils/helper';
import AddLocationModal from '../../ClientsNew/ClientDetails/AddLocationModal';
import {
  PercentageBarSection,
  PercentageText,
  ProjectListStatusBadge,
} from '../../Projects/projectsStyles';
import { CommFirstRoundLetterComp } from '../../Teams/CommonComponents';
import { ProjectCardNoData } from '../ViewLess';
import WorkOrderDeleteModal from '../WorkOrderDeleteModal';

const DetailsWorkOrder = props => {
  const [workOrderName, setWorkOrderName] = useState('');
  const [jobName, setJobName] = useState('');
  const [jobId, setJobId] = useState(null);
  const [saveErrors, setSaveErrors] = useState({});
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [clientOptions, setClientOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('');
  const [jobStatus, setJobStatus] = useState('');
  const [description, setDescription] = useState('');
  const [assignees, setAssignees] = useState(null);
  const [action, setAction] = useState('Add');
  const [costPercentage, setCostPercentage] = useState(0);
  const [hoursPercentage, setHoursPercentage] = useState(0);
  const statusOptions = [
    { value: 'open', label: 'Open' },
    { value: 'in progress', label: 'In Progress' },
    { value: 'complete', label: 'Complete' },
  ];
  const jobStatusOptions = [
    { value: 'unscheduled', label: 'Unscheduled' },
    { value: 'ready to dispatch', label: 'Ready to dispatch' },
  ];
  const getDates = (startDate, endDate) => {
    const dates = [];
    while (moment(startDate).isSameOrBefore(endDate)) {
      dates.push(startDate.format('YYYY-MM-DD'));
      startDate = moment(startDate).add(1, 'days');
    }
    return dates;
  };

  const initialData = {
    labels: getDates(
      moment(new Date()).subtract(6, 'days'),
      moment(new Date()),
    ),
    datasets: [
      {
        label: 'Cost',
        fill: true,
        lineTension: 0.5,
        backgroundColor: '#c6e6e3',
        borderColor: '#20bead',
        borderWidth: 2,
        pointBorderColor: '#20bead',
        pointBackgroundColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#20bead',
        pointBorderWidth: 2,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 5,
        data: [24, 35, 40, 15, 20, 23, 14],
      },
    ],
  };

  const { t } = useTranslation();
  const [jobPanelIsOpen, setJobPanelIsOpen] = useState(false);
  //add Location
  const [popupOpen, setPopupOpen] = useState(false);
  //for hovering in jobs
  const [selectIndex, setSelectIndex] = useState(-1);
  const [toolTip, setToolTip] = useState(-1);

  const [timeDurationValue, setTimeDurationValue] = useState({
    value: 1,
    label: 'Last 7 Days',
  });
  const [graphTypeValue, setGraphTypeValue] = useState({
    value: 2,
    label: 'See by Time Spent',
  });
  const graphDurationOptions = [
    { value: 1, label: 'Last 7 Days' },
    { value: 2, label: 'Last Month' },
    { value: 3, label: 'Last Year' },
  ];
  const graphTypeOptions = [
    { value: 1, label: 'See by Cost' },
    { value: 2, label: 'See by Time Spent' },
  ];
  const [timeSpentData, setTimeSpentData] = useState([]);
  const [graphData, setGraphData] = useState(initialData);
  const [graphType, setGraphType] = useState('time-spent');
  const [graphDuration, setGraphDuration] = useState('week');
  const [costData, setCostData] = useState([]);
  const [currency, setCurrency] = useState('USD');

  //delete WorkOrder
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [workOrderDeleteId, setWorkOrderDeleteId] = useState('');
  //duplicate

  const [duplicateWorkOrder, setDuplicateWorkOrder] = useState(false);
  const [jobAssignee, setJobAssignee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [requiredStartDate, setRequiredStartDate] = useState(null);
  const [requiredEndDate, setRequiredEndDate] = useState(null);

  const {
    selectedOrganization,
    getClientList,
    getClientShortList,
    clientList,
    clientShortList,
    createWorkOrderIsLoading,
    UpdateWorkOrderLoading,
    updateWorkOrder,
    jobUpdate,
    createWorkOrderRequest,
    createJobRequest,
    createdJobId,
    createdJob,
    updateJobRequest,
    createOrUpdateJobIsLoading,
    getJobsUnderWorkOrderRequest,
    //Jobs Under One WorkOrder
    getJobsUnderWorkOrderIsLoading,
    jobsUnderWorkOrderList,
    totalJobsCount,
    pageSizeJobs,
    //client Locations
    getClientLocation,
    clientLocationList,
    getclientLocalMapLoading,
    addClientLocation,
    addOrUpdateclientLocalMapLoading,
    createdClientLocationInfo,
    //workOrder details
    getWorkOrderIsLoading,
    workOrderDetails,
    getWorkOrder,
    //duplicate workorder
    getWorkOrderDuplicateListIsLoading,
    duplicateWorkOrderId,
    getDuplicateWorkOrderequest,
  } = props;

  const handleDeleteProject = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      workOrderDetails &&
      workOrderDetails.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        workorder_id: workOrderDetails.id,
      };
      props.deleteWorkOrderRequest(payload);
      setTimeout(() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
        props.history.push({
          pathname: '/user/work-order',
          state: { from: 'details' },
        });
      }, 500);
    }
  };

  const toggleDeleteModal = workorderId => {
    if (!isDeleteModalOpen) {
      setWorkOrderDeleteId(workorderId);
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  //   useEffect(() => {
  //     if (duplicateWorkOrderId) {
  //       props.history.push(`/user/details-work-order?id=${duplicateWorkOrderId}`);
  //     }
  //   }, [duplicateWorkOrderId]);

  const NoDataGraphSection = ({ graphType, graphDuration }) => {
    const [text, setText] = useState('No data found for this project!');

    useEffect(() => {
      if (graphType && graphDuration) {
        if (graphType === 'time-spent') {
          setText(
            `Seems no time is spent in this project in last ${graphDuration}!`,
          );
        } else if (graphType === 'cost') {
          setText(
            `No expenses found in this project in last ${graphDuration}!`,
          );
        }
      }
    }, [graphType, graphDuration]);
    return (
      <NoDataGraphContainer>
        <NoDataGraph src={noDataGraph} alt="" />
        <NoDataText>{text}</NoDataText>
      </NoDataGraphContainer>
    );
  };

  useEffect(() => {
    if (workOrderDetails && workOrderDetails.name) {
      setWorkOrderName(workOrderDetails.name);
      if (workOrderDetails.client && workOrderDetails.client.id) {
        const clientInfo = {
          value: workOrderDetails.client.id,
          label: workOrderDetails.client.name,
        };
        setSelectedClient(clientInfo);
      }
      setStatus(mapWorkOrderStatusToOption(workOrderDetails.status));
    }
  }, [workOrderDetails]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        let payload = {
          organization_id: selectedOrganization.id,
          workOrder_id: duplicateWorkOrder
            ? duplicateWorkOrderId
            : queryParsed.id,
        };

        getWorkOrder(payload);
        getJobsUnderWorkOrderRequest(payload);
      }
    }
  }, [selectedOrganization, props.location.search, duplicateWorkOrderId]);

  useEffect(() => {
    if (createdClientLocationInfo && createdClientLocationInfo.id) {
      setSelectedLocation({
        value: createdClientLocationInfo.id,
        label: createdClientLocationInfo.name,
      });
    }
  }, [createdClientLocationInfo]);

  useEffect(() => {
    if (createdJob) {
      setAction('Update');
      setJobId(createdJobId);
    }
  }, [createdJob]);

  useEffect(() => {
    if (jobUpdate) {
      toggleJobDetailsPanel(false);
    }
  }, [jobUpdate]);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedClient &&
      selectedClient.value
    ) {
      getClientLocation({
        organization_id: selectedOrganization.id,
        client_id: selectedClient.value,
      });
    }
  }, [selectedClient]);

  useEffect(() => {
    if (clientLocationList && clientLocationList.length > 0) {
      const options = [];
      clientLocationList.map(item => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      // options.unshift({ label: 'Select Location...', value: '' });
      setLocationOptions(options);
    } else if (clientLocationList && clientLocationList.length === 0) {
      setLocationOptions([]);
    }
  }, [clientLocationList]);

  useEffect(() => {
    if (clientShortList && clientShortList.length > 0) {
      const options = [];
      clientShortList.map(item => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      options.unshift({ label: 'Select Customer', value: '' });
      setClientOptions(options);
    } else if (clientShortList && clientShortList.length === 0) {
      setClientOptions([]);
    }
  }, [clientShortList]);

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
    // setAction("add");
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleClearSearch = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      // const payload = {
      //   organization_id: selectedOrganization.id,
      //   page: 1,
      // };
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      // getProjectList(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const onClientSelect = e => {
    if (selectedOrganization && selectedOrganization.id) {
      // const payload = {
      //   organization_id: selectedOrganization.id,
      //   page: 1,
      // };
      // if (searchTerm && searchTerm.trim()) {
      //   payload.name = searchTerm.toLowerCase().trim();
      // }
      if (e && e.value) {
        // payload.client_id = e.value;
        setSelectedClient(e);
      } else {
        setSelectedClient(null);
      }
      // getProjectList(payload);

      // setCurrentPageNumber(1);
    }
  };

  const onLocationSelect = e => {
    setSelectedLocation(e);
  };

  const onStatusSelect = e => {
    setStatus(e);
  };

  const onJobStatusSelect = e => {
    setJobStatus(e);
  };

  const onDescriptionChange = e => {
    const { value } = e.target;
    setDescription(value);
  };

  const onWorkOrderNameChange = e => {
    const { value } = e.target;
    setWorkOrderName(value);

    if (saveErrors && saveErrors['workOrderName']) {
      delete saveErrors['workOrderName'];
    }
  };

  const onJobNameChange = e => {
    const { value } = e.target;
    setJobName(value);

    if (saveErrors && saveErrors['jobName']) {
      delete saveErrors['jobName'];
    }
  };

  const checkJobNameError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!jobName.trim()) {
      formIsValid = false;
      saveErrors['jobName'] = 'Job name is required';
    } else if (checkUrl(jobName)) {
      formIsValid = false;
      saveErrors['jobName'] = 'Job name cannot contain url';
    } else if (checkHtml(jobName)) {
      formIsValid = false;
      saveErrors['jobName'] = 'Invalid Job Name';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!workOrderName.trim()) {
      formIsValid = false;
      saveErrors['workOrderName'] = 'Work order name is required';
    } else if (checkUrl(workOrderName)) {
      formIsValid = false;
      saveErrors['workOrderName'] = 'Work order name cannot contain url';
    } else if (checkHtml(workOrderName)) {
      formIsValid = false;
      saveErrors['workOrderName'] = 'Invalid Work Order Name';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const updateWorkOrderHandler = () => {
    if (checkError()) {
      if (
        selectedOrganization &&
        selectedOrganization.id &&
        workOrderDetails &&
        workOrderDetails.id
      ) {
        let payload = {
          organization_id: selectedOrganization.id,
          workOrder_id: workOrderDetails.id,
          client_id: selectedClient ? selectedClient.value : null,
          name: workOrderName,
          status: status ? status.value : null,
        };
        updateWorkOrder(payload);
      }
    }
  };

  const handleSave = () => {
    if (checkError()) {
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          name: workOrderName,
          client_id: selectedClient ? selectedClient.value : null,
          status: status ? status.value : null,
        };
        createWorkOrderRequest(payload);
      }
    }
  };

  const handleJobSave = save => {
    if (checkJobNameError()) {
      if (
        selectedOrganization &&
        selectedOrganization.id &&
        workOrderDetails &&
        workOrderDetails.id
      ) {
        let payload = {
          organization_id: selectedOrganization.id,
          workOrder_id: workOrderDetails.id,
          name: jobName,
          status: jobStatus ? jobStatus.value : null,
          description: description ? description : null,
          assignees: assignees ? assignees : [],
          // status: status ? status.value : null,
        };
        // if (selectedClient) {
        //   updateWorkOrderHandler();
        // }
        if (action === 'Add') {
          createJobRequest(payload);
        }
        if (action === 'Update') {
          if (jobId) {
            payload.job_id = jobId;
          }

          if (selectedLocation && selectedLocation.value) {
            payload.location_id = selectedLocation.value;
          }
          updateJobRequest(payload);
        }
        if (save === 'close') {
          toggleJobDetailsPanel(false);
        }
      }
    }
  };

  const mapWorkOrderStatusToOption = status => {
    switch (status) {
      case 'open':
        return { value: 'open', label: 'open' };
      case 'close':
        return { value: 'close', label: 'close' };
      default:
        return { value: '', label: 'Unknown Status' };
    }
  };

  const mapStatusToOption = status => {
    switch (status) {
      case 'unscheduled':
        return { value: 'unscheduled', label: 'Unscheduled' };
      case 'ready to dispatch':
        return { value: 'ready to dispatch', label: 'Ready to dispatch' };
      default:
        return { value: status, label: status };
    }
  };

  const selectedJob = job => {
    setJobId(job.id);
    setJobName(job.name);
    setJobStatus(mapStatusToOption(job.status));
    setDescription(
      job.description !== null && job.description !== '' ? job.description : '',
    );
    setSelectedLocation({
      value: job.location ? job.location.id : null,
      label: job.location ? job.location.name : null,
    });
    setAction('Update');

    //for shift update/add
    setJobAssignee(
      job.assignees && job.assignees.length > 0 ? job.assignees : null,
    );
    setSelectedDate(
      job.required_start_date !== null ? job.required_start_date : null,
    );
    setRequiredStartDate(
      job.required_start_date !== null ? job.required_start_date : null,
    );
    setRequiredEndDate(
      job.required_end_date !== null ? job.required_end_date : null,
    );

    toggleJobDetailsPanel(true);
  };

  const clearJobDetails = () => {
    setJobName('');
    setJobStatus({ value: '', label: '' }); // Adjust with your initial value for job status
    setDescription('');
    setAction('Add'); // Adjust with your initial value for action
    toggleJobDetailsPanel(true);
  };

  const toggleJobDetailsPanel = value => {
    setJobPanelIsOpen(value);
  };

  const toolTipChange = (value, index) => {
    setToolTip(value);
    setSelectIndex(index);
  };

  const getHours = time => {
    const hours = time.split(':');
    return `${hours[0]}h ${hours[1]}m`;
  };

  const onGraphDurationChange = e => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      workOrderDetails &&
      workOrderDetails.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: workOrderDetails.id,
        start_date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      };
      setTimeDurationValue(e);
      if (e.value === 1) {
        setGraphDuration('week');
        // getProjectExpenses(payload);
      } else if (e.value === 2) {
        payload.start_date = moment().subtract(29, 'days').format('YYYY-MM-DD');
        setGraphDuration('month');
        // getProjectExpenses(payload);
      } else if (e.value === 3) {
        payload.start_date = moment()
          .subtract(11, 'months')
          .format('YYYY-MM-DD');
        payload.end_date = moment().format('YYYY-MM-DD');
        setGraphDuration('year');
        // props.getProjectYearlyExpenses(payload);
      }
    }
  };

  const onGraphTypeChange = e => {
    setGraphTypeValue(e);
    if (e.value === 1) {
      setGraphType('cost');
    } else if (e.value === 2) {
      setGraphType('time-spent');
    }
  };

  const getData = (canvas, data) => {
    const chartConfig = JSON.parse(JSON.stringify(data));
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(32, 190, 173, 1)');
    gradient.addColorStop(0.33, 'rgba(32, 190, 173, 0.5)');
    gradient.addColorStop(0.66, 'rgba(32, 190, 173, 0.15)');
    gradient.addColorStop(1, 'rgba(32, 190, 173, 0)');

    chartConfig.datasets[0].backgroundColor = gradient;

    return {
      ...chartConfig,
    };
  };

  const handleDuplicateWorkOrder = workorder_id => {
    setDuplicateWorkOrder(true);
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: workorder_id,
      };
      getDuplicateWorkOrderequest(payload);
    }
  };

  return (
    <div className="content">
      <WorkOrderDeleteModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        handleDelete={handleDeleteProject}
        isLoading={props.workOrderDeleteLoading}
      />
      <PageTitle>{t('work_order_details')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push('/user/work-order')}
          subTitle="Back to all work orders"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        {getWorkOrderIsLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {workOrderDetails && workOrderDetails.id && (
              <>
                <FilterSection>
                  <FilterInnerSection columns="auto">
                    <CardTitle>{workOrderDetails.name}</CardTitle>
                  </FilterInnerSection>
                  <FilterInnerSection
                    style={{ marginLeft: `auto` }}
                    columns={
                      getWorkOrderDuplicateListIsLoading
                        ? '190px 190px 120px'
                        : '120px 160px 120px'
                    }
                    columnGap="18px"
                  >
                    <WhiteButtonWithIcon
                      fontSize="14px"
                      style={{ textDecoration: 'underline', border: 'none' }}
                      onClick={() =>
                        handleDuplicateWorkOrder(workOrderDetails.id)
                      }
                    >
                      <img src={duplicate} alt="file" width="15px" />
                      {getWorkOrderDuplicateListIsLoading ? (
                        <ButtonTextLoader
                          loadingText="Duplicating"
                          fontSize="13px"
                          color={colors['greyLodder']}
                        />
                      ) : (
                        t('duplicate')
                      )}
                    </WhiteButtonWithIcon>

                    <WhiteButton
                      fontSize="13px"
                      onClick={() =>
                        props.history.push(
                          `/user/edit-work-order?id=${workOrderDetails.id}`,
                        )
                      }
                    >
                      {t('edit_work_order')}
                    </WhiteButton>

                    <WhiteButtonWithIcon
                      type="delete"
                      fontSize="13px"
                      onClick={() => toggleDeleteModal(workOrderDetails.id)}
                    >
                      <ButtonIcon src={Bean} alt="" />
                      {t('delete')}
                    </WhiteButtonWithIcon>
                  </FilterInnerSection>
                </FilterSection>

                <FilterSection>
                  <FilterInnerSection
                    columns={'100px 20px 65px 300px'}
                    columnGap="10px"
                    style={{ margin: '15px 0 0 0', width: 'fit-content' }}
                  >
                    <ProjectListStatusBadge
                      statusProjectListStatusBadge={workOrderDetails.status}
                      style={{ minWidth: '100px' }}
                    >
                      {workOrderDetails.status}
                    </ProjectListStatusBadge>
                    <p></p>
                    <CommonText fontSize="13px">Customer:</CommonText>
                    <div
                      onClick={() =>
                        props.history.push(
                          `/user/customer-details?id=${workOrderDetails.client.id}`,
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <CommFirstRoundLetterComp
                        text={
                          workOrderDetails.client &&
                          workOrderDetails.client.name
                            ? workOrderDetails.client.name
                            : 'None'
                        }
                        underline={false}
                      />
                    </div>
                  </FilterInnerSection>
                </FilterSection>
              </>
            )}

            <CommonText margin="40px 0 15px" $label>
              Jobs
            </CommonText>
            {getJobsUnderWorkOrderIsLoading ? (
              <CommonText width="15%">
                <ButtonTextLoader
                  justifyContent="flex-start"
                  color={colors['greyLodder']}
                  loadingText={`loading`}
                  fontSize="13px"
                />
              </CommonText>
            ) : (
              jobsUnderWorkOrderList && (
                <SingleSection padding="0px">
                  <CommonFlex style={{ flexWrap: 'wrap', gridRowGap: '10px' }}>
                    {jobsUnderWorkOrderList.map((job, index) => (
                      <div
                      // onMouseEnter={() => toolTipChange(1, index)}
                      // onMouseLeave={() => toolTipChange(-1, -1)}
                      >
                        <WhiteButtonWithIcon
                          key={index}
                          fontSize="12px"
                          onClick={() => selectedJob(job)}
                        >
                          {job.name}
                        </WhiteButtonWithIcon>
                        {selectIndex === index && toolTip === 1 && (
                          <TooltipContainerWorkOrder top="27%">
                            <CommonText
                              fontSize="12px"
                              fontWeight="530"
                              color={colors['white']}
                            >
                              click to show details
                            </CommonText>
                          </TooltipContainerWorkOrder>
                        )}
                      </div>
                    ))}
                  </CommonFlex>
                </SingleSection>
              )
            )}

            {/* Right Side Pannel */}

            <RightSlidingPanel
              isOpen={jobPanelIsOpen}
              closePanel={() => toggleJobDetailsPanel(false)}
              width="500px"
              overlayZIndex={1000}
              contentZIndex={1001}
            >
              <PageTitle style={{ padding: `30px 0 0 30px` }} fontSize="16px">
                {t('job_details')}
              </PageTitle>
              <>
                <InputSection marginLeft="30px">
                  <InputLabel>
                    {t('job_name')} <RedStar>*</RedStar>
                  </InputLabel>
                  <InputField
                    width="60%"
                    marginTop="10px"
                    type="text"
                    placeholder="Type job name here..."
                    value={jobName}
                    onChange={e => onJobNameChange(e)}
                    error={
                      saveErrors &&
                      saveErrors['jobName'] &&
                      saveErrors['jobName'] !== undefined
                    }
                  />
                  {saveErrors &&
                    saveErrors['jobName'] &&
                    saveErrors['jobName'] !== undefined && (
                      <FormAlert margin="0 0 0 5px">
                        {saveErrors['jobName']}
                      </FormAlert>
                    )}
                </InputSection>

                <InputSection marginLeft="30px">
                  <InputLabel>{t('stage')}</InputLabel>
                  <InputWithLabelSection
                    style={{ width: '60%', marginTop: '10px' }}
                  >
                    <Select
                      value={jobStatus}
                      options={jobStatusOptions}
                      onChange={e => onJobStatusSelect(e)}
                      placeholder="Select Job Staus..."
                      styles={FilterDropDownStyle({ height: '40px' })}
                    />
                  </InputWithLabelSection>
                </InputSection>
                <InputSection marginLeft="30px">
                  <InputLabel>{t('description')}</InputLabel>
                  <InputTextArea
                    text="Description"
                    cols="30"
                    rows="5"
                    height="50%"
                    width="80%"
                    value={description}
                    placeholder="Type description here..."
                    onChange={e => onDescriptionChange(e)}
                    style={{ marginTop: '10px' }}
                  ></InputTextArea>
                </InputSection>

                {action === 'Update' &&
                  selectedLocation &&
                  selectedLocation.value !== null && (
                    <>
                      {selectedClient && (
                        <>
                          <DividerLine margin="20px 0 20px" />
                          <InputSection marginLeft="30px">
                            <div style={{ width: '60%' }}>
                              <InputLabel>
                                {t('location')}{' '}
                                {/* <span
                                onClick={() => popupToggle()}
                                style={{
                                  float: 'right',
                                  color: '#18B54D',
                                  fontWeight: '600',
                                  textAlign: 'left',
                                  cursor: 'pointer',
                                  fontSize: '12px',
                                }}
                              >
                                {t('add_new_location')}
                              </span> */}
                              </InputLabel>
                            </div>

                            {clientLocationList &&
                            clientLocationList.length > 0 ? (
                              getclientLocalMapLoading ? (
                                <CommonText width="25%">
                                  <ButtonTextLoader
                                    color={colors['greyLodder']}
                                    loadingText={`loading`}
                                    fontSize="13px"
                                    justifyContent="flex-start"
                                  />
                                </CommonText>
                              ) : (
                                <InputWithLabelSection
                                  style={{ width: '60%', marginTop: '10px' }}
                                >
                                  <Select
                                    value={selectedLocation}
                                    options={locationOptions}
                                    onChange={e => onLocationSelect(e)}
                                    // placeholder="Select Location..."
                                    styles={FilterDropDownStyle({
                                      height: '40px',
                                    })}
                                  />
                                </InputWithLabelSection>
                              )
                            ) : (
                              <CommonText margin="20px 0 0 0">
                                No location previously set for this customer
                              </CommonText>
                            )}
                          </InputSection>
                        </>
                      )}
                    </>
                  )}
                {(jobStatus.value === 'ready to dispatch' ||
                  jobStatus.value === 'completed') &&
                  selectedDate != null &&
                  requiredStartDate != null &&
                  requiredEndDate != null && (
                    <>
                      <DividerLine margin="20px 0 20px" />
                      <InputSection marginLeft="30px">
                        <InputLabel>{t('shift')}</InputLabel>
                        <ViewLessCard
                          style={{
                            width: '60%',
                            marginTop: '15px',
                          }}
                        >
                          <CardTextData fontSize="14px">
                            <CommonText
                              fontSize="12.5px"
                              style={{ marginBottom: '10px' }}
                            >
                              Selected Date:{' '}
                              {moment(selectedDate).format('YYYY-MM-DD')}
                            </CommonText>
                            <CommonText fontSize="12.5px">
                              Start Time:{' '}
                              {moment(requiredStartDate).format('h:mm a')}
                            </CommonText>
                            <CommonText fontSize="12.5px">
                              End Time:{' '}
                              {moment(requiredEndDate).format('h:mm a')}
                            </CommonText>
                          </CardTextData>
                        </ViewLessCard>
                      </InputSection>
                    </>
                  )}

                <CommonFlex margin="30px 0px 0 30px" gap="15px">
                  <WhiteButton
                    onClick={() => toggleJobDetailsPanel(false)}
                    width="25%"
                  >
                    {t('close')}
                  </WhiteButton>
                </CommonFlex>
              </>
            </RightSlidingPanel>

            <DetailTitleSelectSection style={{ margin: '20px 0 0 0' }}>
              <CommonText title name>
                Progress Report
              </CommonText>
              {props.projectExpensesIsLoading ? (
                <AssignButtonLoader
                  padding="9px 10px"
                  borderColor="rgb(228, 231, 235)"
                  width="150px"
                />
              ) : (
                <Select
                  isSearchable={false}
                  value={timeDurationValue}
                  options={graphDurationOptions}
                  placeholder=""
                  onChange={e => {
                    onGraphDurationChange(e);
                  }}
                  styles={CreateProjectDropdown(null)}
                />
              )}
            </DetailTitleSelectSection>

            <ChartContainer>
              <div style={{ maxWidth: `180px` }}>
                <Select
                  isSearchable={false}
                  value={graphTypeValue}
                  options={graphTypeOptions}
                  placeholder=""
                  onChange={e => {
                    onGraphTypeChange(e);
                  }}
                  styles={CreateProjectDropdown(null)}
                />
              </div>
              <div>
                {graphType &&
                  graphType === 'time-spent' &&
                  (timeSpentData && timeSpentData.length > 0 ? (
                    <Line
                      data={canvas => getData(canvas, graphData)}
                      options={projectChartOptions(graphType, '')}
                    />
                  ) : (
                    <NoDataGraphSection
                      graphType={graphType}
                      graphDuration={graphDuration}
                    />
                  ))}
                {graphType &&
                  graphType === 'cost' &&
                  (costData && costData.length > 0 ? (
                    <Line
                      data={canvas => getData(canvas, graphData)}
                      options={projectChartOptions(
                        graphType,
                        currencySymbolMap[currency],
                      )}
                    />
                  ) : (
                    <NoDataGraphSection
                      graphType={graphType}
                      graphDuration={graphDuration}
                    />
                  ))}
              </div>
            </ChartContainer>

            <ViewLessCardContainer
              style={{ marginBottom: '15px', marginTop: '20px' }}
              padding="15px 0"
            >
              <ViewLessCard>
                <div>
                  <CommonText fontSize="12px">
                    {workOrderDetails &&
                    workOrderDetails.budget &&
                    workOrderDetails.budget.budget_type === 'work_order_hours'
                      ? 'Budgeted Hours'
                      : 'Total Budget'}
                  </CommonText>
                  {workOrderDetails &&
                  workOrderDetails.budget &&
                  workOrderDetails.budget.amount ? (
                    <CardTextData margin="4px 0 0" fontSize="14px">
                      {workOrderDetails.budget.budget_type === 'work_order_fees'
                        ? `${currencySymbolMap[currency]} `
                        : ``}{' '}
                      {workOrderDetails.budget.budget_type === 'work_order_fees'
                        ? (workOrderDetails.budget.amount / 100).toFixed(2)
                        : workOrderDetails.budget.amount}
                      {workOrderDetails.budget.budget_type ===
                      'work_order_hours'
                        ? `hours`
                        : ``}
                    </CardTextData>
                  ) : (
                    <ProjectCardNoData type="budget" text="Budget not set" />
                  )}
                </div>
                {workOrderDetails &&
                  workOrderDetails.budget &&
                  workOrderDetails.budget.budget_left !== null && (
                    <BudgetLeftSection>
                      <ProjectCardLabel>
                        {workOrderDetails.budget.budget_type ===
                        'work_order_fees'
                          ? 'Left'
                          : 'Remaining'}
                      </ProjectCardLabel>
                      <ProjectCardText>
                        {workOrderDetails.budget.budget_type ===
                        'work_order_fees'
                          ? `${currencySymbolMap[currency]} ${(
                              workOrderDetails.budget.budget_left / 100
                            ).toFixed(2)}`
                          : `${workOrderDetails.budget.budget_left} hours`}
                      </ProjectCardText>
                    </BudgetLeftSection>
                  )}
              </ViewLessCard>

              <ViewLessCard>
                <div>
                  <CommonText fontSize="12px">
                    Spent{' '}
                    {workOrderDetails &&
                    workOrderDetails.budget &&
                    workOrderDetails.budget.budget_type === 'work_order_hours'
                      ? 'Hours'
                      : 'Amount'}
                  </CommonText>
                  {workOrderDetails &&
                  workOrderDetails.budget &&
                  workOrderDetails.budget.amount ? (
                    <CardTextData margin="4px 0 0" fontSize="14px">
                      {workOrderDetails.budget.budget_type ===
                      'work_order_hours'
                        ? workOrderDetails.spent_hours
                          ? getHours(workOrderDetails.spent_hours)
                          : '0h 00m'
                        : workOrderDetails.budget.cost
                        ? `${currencySymbolMap[currency]} ${(
                            workOrderDetails.budget.cost / 100
                          ).toFixed(2)}`
                        : `${currencySymbolMap[currency]} 0.00`}
                    </CardTextData>
                  ) : (
                    <ProjectCardNoData type="budget" text="Budget not set" />
                  )}
                </div>
                {workOrderDetails &&
                workOrderDetails.budget &&
                workOrderDetails.budget.amount ? (
                  <PercentageBarSection>
                    <LinearActivityBar
                      width="100%"
                      height="8px"
                      borderRadius="4px"
                      yellow="#20bead"
                      red="#20bead"
                      green="#20bead"
                      percent={
                        workOrderDetails.budget.budget_type ===
                          'work_order_hours' && hoursPercentage
                          ? hoursPercentage
                          : workOrderDetails.budget.budget_type ===
                              'work_order_fees' && costPercentage
                          ? costPercentage
                          : 0
                      }
                      marginTop="0"
                      backgroundColor="#B5B5B5"
                    />
                    <PercentageText>
                      {workOrderDetails.budget.budget_type ===
                        'work_order_fees' && costPercentage
                        ? costPercentage
                        : workOrderDetails.budget.budget_type ===
                            'work_order_hours' && hoursPercentage
                        ? hoursPercentage
                        : 0}
                      %
                    </PercentageText>
                  </PercentageBarSection>
                ) : null}
              </ViewLessCard>
              <ViewLessCard>
                <div>
                  <CommonText fontSize="12px">
                    Spent{' '}
                    {workOrderDetails &&
                    workOrderDetails.budget &&
                    workOrderDetails.budget.budget_type === 'work_order_hours'
                      ? 'Amount'
                      : 'Hours'}
                  </CommonText>
                  {workOrderDetails &&
                  workOrderDetails.budget &&
                  workOrderDetails.budget.budget_type === 'work_order_hours' ? (
                    <ProjectCardNoData type="budget" />
                  ) : workOrderDetails &&
                    workOrderDetails.spent_hours &&
                    getHours(workOrderDetails.spent_hours) !== '00h 00m' ? (
                    <CardTextData margin="4px 0 0" fontSize="14px">
                      {getHours(workOrderDetails.spent_hours)}
                    </CardTextData>
                  ) : (
                    <ProjectCardNoData
                      type="hour"
                      text="Time not tracked yet"
                    />
                  )}
                </div>
              </ViewLessCard>
              <ViewLessCard>
                <div>
                  <CommonText fontSize="12px">Billable Amount</CommonText>
                  {workOrderDetails && workOrderDetails.billable_id ? (
                    <CardTextData margin="4px 0 0" fontSize="14px">
                      {currencySymbolMap[currency]}{' '}
                      {workOrderDetails.billable_amount.toFixed(2)}
                    </CardTextData>
                  ) : (
                    <ProjectCardNoData type="budget" text="Billing not set" />
                  )}
                </div>
              </ViewLessCard>
              <ViewLessCard>
                <div>
                  <CommonText fontSize="12px">Uninvoiced Amount</CommonText>
                  {workOrderDetails && workOrderDetails.billable_id ? (
                    <CardTextData
                      margin="4px 0 0"
                      fontSize="14px"
                      textColor={
                        workOrderDetails.uninvoiced_amount &&
                        workOrderDetails.uninvoiced_amount.toFixed(2) < 0
                          ? '#FE5969'
                          : '#20bead'
                      }
                    >
                      {currencySymbolMap[currency]}{' '}
                      {workOrderDetails.uninvoiced_amount.toFixed(2)}
                    </CardTextData>
                  ) : (
                    <ProjectCardNoData type="budget" text="Billing not set" />
                  )}
                </div>
                {workOrderDetails && workOrderDetails.billable_id && (
                  <BudgetLeftSection>
                    <ProjectCardLabel>Invoiced</ProjectCardLabel>
                    <ProjectCardText>
                      {currencySymbolMap[currency]}{' '}
                      {(
                        workOrderDetails.billable_amount -
                        workOrderDetails.uninvoiced_amount
                      ).toFixed(2)}
                    </ProjectCardText>
                  </BudgetLeftSection>
                )}
              </ViewLessCard>
            </ViewLessCardContainer>
          </>
        )}
      </Container>
    </div>
  );
};

export default DetailsWorkOrder;
