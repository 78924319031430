import { connect } from 'react-redux';

import { getOfficesShortList } from '../Offices/officesActions';
import { getOrganizationMembersShortList } from '../Projects/projectsActions';
import { getJobShiftsList } from '../ShiftSchedule/shiftScheduleActions';
import { getWorkOrderShortList } from '../WorkOrderNew/workOrderActions';
// Component
import Jobs from './Jobs';
import { deleteJob } from './jobsActions';
import { createTask, deleteTask, duplicateTask } from './tasksActions';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersShortList: state.project.orgMembersShortList,
  officesShortList: state.office.officesShortList,
  duplicateIsLoading: state.task.duplicateIsLoading,
  deleteIsLoading: state.task.deleteIsLoading,
  overlap: state.task.overlap,
  duplicateTaskInfo: state.task.duplicateTaskInfo,
  isLoading: state.task.isLoading,
  loadingTaskAssigneeId: state.project.loadingTaskAssigneeId,
  // pagination
  totalTasksCount: state.task.tasksListCount,
  pageSize: state.task.taskListPageSize,
  // jobs
  isLoadingJobs: state.schedule.jobListIsLoading,
  totaljobsCount: state.schedule.totaljobsCount,
  pageSizeJob: state.schedule.pageSizeJob,
  jobList: state.schedule.jobShiftsList,
  reloadJobList: state.schedule.reloadJobList,
  deletingJobId: state.schedule.deletingJobId,
  jobDeleteLoading: state.schedule.jobDeleteLoading,
  // Client
  clientShortList: state.client.clientShortList,
  //workordershort
  workOrderShortList: state.workorder.workOrderShortList,
  //Delete Job
  filters: state.filterReducer.filters,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  createTask: params => dispatch(createTask(params)),
  deleteTask: params => dispatch(deleteTask(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  getJobList: params => dispatch(getJobShiftsList(params)),
  getWorkOrderShortList: params => dispatch(getWorkOrderShortList(params)),
  deleteJob: details => dispatch(deleteJob({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
