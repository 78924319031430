import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Plus from '../../assets/img/icons/plus_white.svg';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import PricingPlan from '../../components/PricingPlan/PricingPlan';
import Select from '../../components/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import ClearFilterButton from '../../components/Search/clearFilterButton';
import { setPathName } from '../../filterReducer';
import {
  ProjectRightButtonContainer,
  TabSearchGrid,
} from '../../styledComponents/ProjectsNew';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  Container,
  FilterLabel,
  HeaderContainer,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { ProjectListContainer } from '../Projects/projectsStyles';
import WorkOrderDeleteModal from './WorkOrderDeleteModal';
import WorkOrdersList from './WorkOrderList';

const WorkOrder = ({
  selectedOrganization,
  getClientShortList,
  clientShortList,
  history,
  // WorkOrder
  WorkOrderList,
  getWorkOrderListIsLoading,
  getWorkOrderDuplicateListIsLoading,
  pageSizeWorkOrder,
  totalWorkOrderCount,
  reloadWorkOrderList,
  getWorkOrderRequest,
  deleteWorkOrderRequest,
  getDuplicateWorkOrderequest,
  duplicateWorkOrderId,
  //Billing and Budgeting
  getWorkOrderBudgetAndBillable,
  workOrderBudgetAndBillable,
  workOrderBudgetAndBillableIsLoading,
  filters,
  workOrderDeleteLoading,
}) => {
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [workOrderDeleteId, setWorkOrderDeleteId] = useState('');

  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(
    filters?.pathname === '/user/work-order' && filters?.selectedClient
      ? filters?.selectedClient
      : {
          label: '(All Customers)',
          value: '',
        },
  );

  const [expandedWorkOrderId, setExpandedWorkOrderId] = useState(null);
  const [isTotalWorkOrderCounted, setIsTotalWorkOrderCounted] = useState(false);

  const [searchTerm, setSearchTerm] = useState(
    filters?.pathname === '/user/work-order' && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : '',
  );
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState(
    filters?.pathname === '/user/work-order' && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : '',
  );
  const [pricingPopup, setPricingPopup] = useState(false);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    if (reloadWorkOrderList) {
      callGetWorkOrderRequest();
    }
  }, [reloadWorkOrderList]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      getClientShortList({ organization_id: selectedOrganization.id });
    }
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.name === 'Standard'
    ) {
      pricingPopupToggle();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      !isTotalWorkOrderCounted
    ) {
      console.log(WorkOrderList);
    }
  }, [WorkOrderList]);

  useEffect(() => {
    if (clientShortList && clientShortList.length > 0) {
      const options = [];
      clientShortList.map(item => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      options.unshift({ label: '(All Customers)', value: '' });
      setClientOptions(options);
    } else if (clientShortList && clientShortList.length === 0) {
      setClientOptions([]);
    }
  }, [clientShortList]);

  useMemo(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      expandedWorkOrderId
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        workorder_id: expandedWorkOrderId,
      };

      // getProjectBudgetAndBillable(payload);
      getWorkOrderBudgetAndBillable(payload);
    }
  }, [expandedWorkOrderId]);

  const handleSearchByName = () => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      if (searchTerm && searchTerm.trim()) {
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm('');
      }
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      setIsTotalWorkOrderCounted(true);
      getWorkOrderRequest(payload);

      setCurrentPageNumber(1);
    }
  };
  const handleClearSearch = () => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
  };

  const onClientSelect = e => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: 1,
      };
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
      }
      if (e && e.value) {
        payload.client_id = e.value;
      }
      setIsTotalWorkOrderCounted(true);
      // else {
      //   setSelectedClient(null);
      // }
      setSelectedClient(e);
      getWorkOrderRequest(payload);
      setCurrentPageNumber(1);
    }
  };

  const callGetWorkOrderRequest = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: currentPageNumber,
      };
      if (currentSearchedTerm && currentSearchedTerm.trim()) {
        payload.name = currentSearchedTerm.toLowerCase().trim();
      }
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      getWorkOrderRequest(payload);
    }
  };
  useEffect(() => {
    callGetWorkOrderRequest();
  }, [
    selectedClient,
    currentPageNumber,
    currentSearchedTerm,
    selectedOrganization,
  ]);

  const handlePageChange = page => {
    setCurrentPageNumber(page);
  };

  const selectProjectType = value => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      const payload = {
        organization_id: selectedOrganization.id,
        is_active: value,
      };
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      getWorkOrderRequest(payload);
    }
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setWorkOrderType(value);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleDeleteProject = () => {
    if (selectedOrganization && selectedOrganization.id && workOrderDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        workorder_id: workOrderDeleteId,
        reloadWorkOrderList:
          Math.ceil(totalWorkOrderCount / pageSizeWorkOrder) !==
          currentPageNumber,
      };
      deleteWorkOrderRequest(payload);
      setTimeout(() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }, 300);
    }
  };

  const toggleDeleteModal = workorderId => {
    if (!isDeleteModalOpen) {
      setWorkOrderDeleteId(workorderId);
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const pricingPopupToggle = () => {
    setPricingPopup(!pricingPopup);
  };

  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        currentSearchedTerm,
        selectedClient,
      }),
    );
  }, [currentSearchedTerm, selectedClient]);
  return (
    <div className="content">
      <WorkOrderDeleteModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        handleDelete={handleDeleteProject}
        isLoading={workOrderDeleteLoading}
      />

      <PricingPlan
        isOpen={pricingPopup}
        toggle={pricingPopupToggle}
        popupText="You don't have permission to this feature."
        popupTitle="Upgrade to Elite to access this feature"
        history={history}
        backRoute="/user/dashboard"
        onlyElite
      />

      <PageTitle>{t('work_orders')}</PageTitle>
      <HeaderContainer>
        <TabSearchGrid columns="230px 280px">
          {selectedOrganization && selectedOrganization.role !== 'member' && (
            <InputWithLabelSection>
              <FilterLabel>{t('customer')}</FilterLabel>
              <Select
                isClearable={selectedClient?.value}
                value={selectedClient}
                options={clientOptions}
                onChange={e =>
                  e
                    ? onClientSelect(e)
                    : onClientSelect({
                        label: '(All Customers)',
                        value: '',
                      })
                }
                placeholder="Select Customer..."
                styles={FilterDropDownStyle({ height: '40px' })}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: props => {
                    return <ClearFilterButton {...props} />;
                  },
                }}
              />
            </InputWithLabelSection>
          )}

          <SearchWithButton
            itemName=""
            searchTermName="work order"
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          />
        </TabSearchGrid>
        {selectedOrganization && selectedOrganization.role !== 'member' && (
          <ProjectRightButtonContainer>
            <ColoredButtonWithIcon
              onClick={() => history.push('/user/create-work-order')}
            >
              <ButtonIcon src={Plus} alt="" />
              Create Work Order
            </ColoredButtonWithIcon>
          </ProjectRightButtonContainer>
        )}
      </HeaderContainer>
      {totalWorkOrderCount && totalWorkOrderCount > 0 && pageSizeWorkOrder ? (
        <TopPagination
          itemName="Work Orders"
          totalCount={totalWorkOrderCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSizeWorkOrder}
        />
      ) : null}

      {getWorkOrderListIsLoading ? (
        <Container>
          <ComponentCircleLoader />
        </Container>
      ) : (
        <ProjectListContainer>
          <WorkOrdersList
            selectedOrganization={selectedOrganization}
            isLoading={getWorkOrderListIsLoading}
            searchTerm={searchTerm}
            selectedClient={selectedClient}
            WorkOrderList={WorkOrderList}
            history={history}
            toggleDeleteModal={toggleDeleteModal}
            duplicateWorkOrder={getDuplicateWorkOrderequest}
            duplicateWorkOrderIsLoading={getWorkOrderDuplicateListIsLoading}
            duplicateProjectId={duplicateWorkOrderId}
            getProjectBudgetAndBillable={getWorkOrderBudgetAndBillable}
            workOrderBudgetAndBillableIsLoading={
              workOrderBudgetAndBillableIsLoading
            }
            workOrderBudgetAndBillable={workOrderBudgetAndBillable}
            expandedWorkOrderId={expandedWorkOrderId}
            setExpandedWorkOrderId={setExpandedWorkOrderId}
          />
        </ProjectListContainer>
      )}
      {totalWorkOrderCount && totalWorkOrderCount > 0 && pageSizeWorkOrder ? (
        <BottomPagination
          currentPage={currentPageNumber}
          totalCount={totalWorkOrderCount}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSizeWorkOrder}
        />
      ) : null}
    </div>
  );
};

export default WorkOrder;
