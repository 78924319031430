import styled, { css } from 'styled-components';

export const TaskInfoSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 30px;
  justify-content: start;
  align-items: center;
`;
export const LabelTextBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const StatusBar = styled.div`
  display: flex;
  align-self: center;
  color: ${({ theme }) => theme.colors.main.white};
  padding: 6px 15px;
  border-radius: 6px;
  background-color: ${({ status, theme }) => theme.colors.main[status]};
  font-size: 13px;
  font-weight: 600;
`;
export const EmployeeInfoBlock = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
export const CallSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const EvidenceThumbnail = styled.div`
  width: 260px;
  border-radius: 6px;
  cursor: pointer;
`;
export const NoteSection = styled.div`
  display: grid;
  margin: 10px -30px 0 -30px;
  grid-template-columns: minmax(auto, 780px);
  border-top: 1px solid #c2cce1;
  padding: 15px 30px 0;
  gap: 15px;
`;

export const EvidenceIconSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 20px);
  gap: 12px;
  justify-content: start;
  align-items: start;
`;
export const EvidenceIconSectionFlex = styled.div`
  display: flex;
  grid-template-columns: repeat(2, 20px);
  gap: 12px;
  justify-content: start;
  align-items: start;
`;

export const EvidenceIcon = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:hover {
    box-shadow: 0.4px 0.4px 0.4px #c2cce1;
  }
`;
