import { connect } from 'react-redux';

import { getClientShortList } from '../ClientsNew/clientsActions';
import WorkOrder from './WorkOrder';
import {
  deleteWorkOrderRequest,
  getDuplicateWorkOrderequest,
  getWorkOrderBudgetAndBillable,
  getWorkOrderRequest,
} from './workOrderActions';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  clientShortList: state.client.clientShortList,
  duplicateWorkOrderId: state.workorder.duplicateWorkOrderId,
  //WorkOrder
  WorkOrderList: state.workorder.WorkOrderList,
  getWorkOrderListIsLoading: state.workorder.getWorkOrderListIsLoading,
  pageSizeWorkOrder: state.workorder.pageSizeWorkOrder,
  totalWorkOrderCount: state.workorder.totalWorkOrderCount,
  reloadWorkOrderList: state.workorder.reloadWorkOrderList,
  getWorkOrderDuplicateListIsLoading:
    state.workorder.getWorkOrderDuplicateListIsLoading,
  // Billing and Budgeting
  workOrderBudgetAndBillable: state.workorder.workOrderBudgetAndBillable,
  workOrderBudgetAndBillableIsLoading:
    state.workorder.workOrderBudgetAndBillableIsLoading,
  workOrderDeleteLoading: state.workorder.workOrderDeleteLoading,
  filters: state.filterReducer.filters,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  //Get project
  getClientShortList: details => dispatch(getClientShortList({ ...details })),

  getWorkOrderRequest: details => dispatch(getWorkOrderRequest({ ...details })),
  deleteWorkOrderRequest: details =>
    dispatch(deleteWorkOrderRequest({ ...details })),
  getDuplicateWorkOrderequest: details =>
    dispatch(getDuplicateWorkOrderequest({ ...details })),
  getWorkOrderBudgetAndBillable: details =>
    dispatch(getWorkOrderBudgetAndBillable({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);
