import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import arrowIcon from '../../../assets/img/icons/arrow-right-black.svg';
import { CommonImage, CommonText } from '../../../styledComponents/common';
import FilterDropDownStyle from '../../DropdownStyle/FilterDropDownStyle';
import Select from '../../ReactSelectDropdown';
import { usePagination } from '../BottomPagination/usePagination';
import {
  DisplayCountTextSection,
  PaginationButton,
  TopPaginationContainer,
  TopPaginationSection,
} from '../paginationStyles';

const TopPagination = props => {
  const {
    totalCount,
    currentPage,
    onPageChange,
    pageSize,
    itemName,
    padding,
    margin,
    siblingCount = 1,
  } = props;

  const { t } = useTranslation();

  const totalPages = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    return totalPageCount;
  }, [totalCount, pageSize]);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  useEffect(() => {
    if (currentPage > paginationRange[paginationRange.length - 1]) {
      onPageChange(paginationRange[paginationRange.length - 1]);
    }
  }, [totalCount]);

  const pageOptions = useMemo(() => {
    const length = totalPages;

    return Array.from({ length }, (_, idx) => {
      return { label: idx + 1, value: idx + 1 };
    });
  }, [totalPages]);

  if (currentPage === 0 || totalPages < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <TopPaginationContainer padding={padding} margin={margin}>
      <DisplayCountTextSection>
        <CommonText>
          {t('displaying')} {(currentPage - 1) * pageSize + 1}-
          {currentPage * pageSize < totalCount
            ? currentPage * pageSize
            : totalCount}{' '}
          {t('of')} {totalCount} {itemName}
        </CommonText>
      </DisplayCountTextSection>
      <TopPaginationSection>
        <PaginationButton
          onClick={onPrevious}
          previous
          disabled={currentPage === 1}
          size="28px"
        >
          <CommonImage src={arrowIcon} alt="previous" />
        </PaginationButton>
        <CommonText $label fontWeight="500">
          {t('showing_page')}
        </CommonText>
        <Select
          isSearchable
          value={{ label: currentPage, value: currentPage }}
          options={pageOptions}
          onChange={e => {
            if (e.value !== currentPage) {
              onPageChange(e.value);
            }
          }}
          styles={FilterDropDownStyle({
            height: '28px',
            width: '50px',
            menuWidth: '50px',
            indicatorWidth: '32px',
            valuePadding: '0 8px',
          })}
          indicatorIconSize="18px"
        />
        <CommonText $label fontWeight="500">
          {t('of')} {totalPages}
        </CommonText>
        <PaginationButton
          onClick={onNext}
          disabled={currentPage === totalPages}
          size="28px"
        >
          <CommonImage src={arrowIcon} alt="next" />
        </PaginationButton>
      </TopPaginationSection>
    </TopPaginationContainer>
  );
};

export default TopPagination;
