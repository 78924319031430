import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Plus from '../../assets/img/icons/plus_white.svg';
import BackButtonComponent from '../../components/BackButtonComponent/BackButtonComponent';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import SearchWithButton from '../../components/Search/SearchWithButton';
import { setPathName } from '../../filterReducer';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  ContentHeader,
  HeaderContainer,
  PageTitle,
} from '../../styledComponents/common';
import { TabLeftGrid } from '../../styledComponents/offices';
import OfficeList from './OfficesList';

const Offices = ({
  isLoading,
  getOfficesList,
  officesList,
  history,
  clearOfficeCreatedUpdated,
  selectedOrganization,
  deleteOffice,
  totalOfficesCount,
  filters,
  pageSize,
  reloadOfficeList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState('list');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  //search
  const [searchTerm, setSearchTerm] = useState(
    filters?.pathname === '/user/offices' && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : '',
  );
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState(
    filters?.pathname === '/user/offices' && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : '',
  );
  // delete
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteOfficeId, setDeleteOfficeId] = useState(null);

  const [selectedOffice, setSelectedOffice] = useState(null);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        currentSearchedTerm,
      }),
    );
  }, [currentSearchedTerm]);

  const handleSearchByName = () => {
    if (searchTerm && searchTerm.trim()) {
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm('');
    }
    setCurrentPageNumber(1);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setCurrentPageNumber(1);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const toggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleOfficeDelete = officeId => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        office_id: officeId,
        reloadOfficeList:
          Math.ceil(totalOfficesCount / pageSize) !== currentPageNumber,
      };
      deleteOffice(payload);
      setIsDeleteModalOpen(!isDeleteModalOpen);
    }
  };

  const handlePageChange = page => {
    setCurrentPageNumber(page);
  };
  const fetchOffices = async () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: currentPageNumber,
      };
      if (currentSearchedTerm?.length > 0) {
        payload.name = encodeURIComponent(
          currentSearchedTerm.toLowerCase().trim(),
        );
      }
      await getOfficesList(payload);
    }
  };

  useEffect(() => {
    if (reloadOfficeList) {
      fetchOffices();
    }
  }, [reloadOfficeList]);

  useEffect(() => {
    if (selectedOrganization?.id) {
      fetchOffices();
    }
  }, [selectedOrganization, currentPageNumber, currentSearchedTerm]);

  return (
    <div className="content">
      <PageTitle>{t('offices')}</PageTitle>
      <HeaderContainer>
        <TabLeftGrid style={{ width: '280px' }}>
          <SearchWithButton
            itemName={t('office')}
            searchTermName={t('office_info')}
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          />
        </TabLeftGrid>
        {selectedOrganization &&
          (selectedOrganization.role === 'admin' ||
            selectedOrganization.role === 'owner') && (
            <ColoredButtonWithIcon
              onClick={() => history.push('/user/add-office')}
            >
              <ButtonIcon src={Plus} alt="" />
              {t('create_office')}
            </ColoredButtonWithIcon>
          )}
      </HeaderContainer>

      {currentPage === 'details' && (
        <ContentHeader>
          <BackButtonComponent
            onClick={() => handleChangePage('list')}
            subTitle={t('back_to_all_offices')}
          />
        </ContentHeader>
      )}

      {totalOfficesCount && totalOfficesCount > 0 && pageSize ? (
        <TopPagination
          itemName={t('offices')}
          totalCount={totalOfficesCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <OfficeList
        searchTerm={searchTerm}
        officeList={officesList}
        history={history}
        modalToggle={toggle}
        isModalOpen={isDeleteModalOpen}
        handleDelete={handleOfficeDelete}
        isLoading={isLoading}
        clientUpdateLoading={false}
        updatingClientId={null}
        clientDeleteLoading={false}
        deletingClientId={null}
        PageNumber={currentPageNumber}
        selectedOrganization={selectedOrganization}
      />

      {totalOfficesCount && totalOfficesCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalOfficesCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Offices;
