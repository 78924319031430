import FirstRoundLetterComp from '../../components/FirstRoundLetter/FirstRoundLetter';
import { useState } from 'react';

import ToggleButton from '../../components/ToggleButton/ToggleButton';
import HelpTooltip from '../../components/Tooltip/HelpTooltip';
import {
  CommonFlex,
  CommonText,
  FirstWordRound,
  MailSendSection,
  ResendInvite,
  RoundMiniImage,
  TableText,
  TextInputSection,
} from '../../styledComponents/common';
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from '../../styledComponents/createProject';
import {
  ActivityTextSection,
  RadioButton,
  TimesheetApprovalSettingsBadge,
} from '../../styledComponents/members';

export const ApprovalSettingsSection = props => {
  const {
    margin,
    active,
    hasTooltip,
    handleChange,
    memberId,
    isAdmin,
    fontSize,
  } = props;

  const handleClick = () => {
    if (memberId) {
      handleChange(memberId, !active);
    }
  };
  return (
    <CommonFlex margin={margin || '10px 0 0 0'} gap="12px">
      <CommonText fontSize={fontSize} $label fontWeight={!hasTooltip && '500'}>
        Timesheet Approval
      </CommonText>
      {hasTooltip && (
        <HelpTooltip toolTipText="Control whether this member will require timesheet approval or not." />
      )}
      {isAdmin ? (
        <ToggleButton
          active={active}
          handleClick={handleClick}
          margin={hasTooltip && '0 0 0 10px'}
        />
      ) : (
        <TimesheetApprovalSettingsBadge active={active}>
          {active ? 'ON' : 'OFF'}
        </TimesheetApprovalSettingsBadge>
      )}
    </CommonFlex>
  );
};

export const GeofenceStatusSection = props => {
  const { margin, active, hasTooltip, handleChange, memberId, notMember } =
    props;

  const handleClick = value => {
    if (memberId && notMember) {
      handleChange(memberId, value);
    }
  };
  return (
    <TextInputSection>
      <CommonFlex gap="12px">
        <CommonText $label fontWeight={!hasTooltip && '500'}>
          Enable Geofence Status for Time Clock
        </CommonText>
        {hasTooltip && (
          <HelpTooltip toolTipText="Manage whether this member operates from the office or remotely." />
        )}
      </CommonFlex>
      <CommonFlex
        gap="10px"
        margin="0 auto 0 0"
        style={{ cursor: `pointer` }}
        onClick={() => handleClick(false)}
      >
        <RadioButton active={!active} />
        <CommonText paddingLeft="45px" fontWeight="500">
          Remote User
        </CommonText>
      </CommonFlex>
      <CommonFlex
        gap="10px"
        margin="0 auto 0 0"
        style={{ cursor: `pointer` }}
        onClick={() => handleClick(true)}
      >
        <RadioButton active={active} />
        <CommonText fontWeight="500">Office User</CommonText>
      </CommonFlex>
    </TextInputSection>
  );
};

export const CommImageNameComp = props => (
  <AssigneesManageImgTextGrid hoverUnderline={props.hoverUnderline ?? false}>
    <AssigneesManageRoundImage imageSize={props.size}>
      {props.imgSource[0] !== '/' ? (
        <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
      ) : (
        <FirstRoundLetterComp
          text={props.name}
          backColor={props.index}
          color={props.color}
          size={props.size}
          fontSize={props.fontSize}
        />
      )}
    </AssigneesManageRoundImage>
    {props.email ? (
      <ActivityTextSection style={{ alignContent: 'center' }}>
        <TableText
          name
          // style={{ textDecoration: 'none' }}
          hoverUnderline={props.hoverUnderline ?? false}
          // onMouseOver={e =>
          //   (e.currentTarget.style.textDecoration = 'underline')
          // }
          // onMouseOut={e => (e.currentTarget.style.textDecoration = 'none')}
        >
          {props.name}
        </TableText>
        <TableText fontWeight="500">{props.email}</TableText>
      </ActivityTextSection>
    ) : (
      <TableText
        name
        fontSize={props.fontSize}
      hoverUnderline={props.hoverUnderline ?? false}
      >
        {props.name}
      </TableText>
    )}
  </AssigneesManageImgTextGrid>
);

export const CommImageInviteComp = props => (
  <AssigneesManageImgTextGrid>
    <AssigneesManageRoundImage imageSize={props.size}>
      <FirstRoundLetterComp
        text={props.member.email}
        backColor={props.index}
        color={props.member.color}
      />
    </AssigneesManageRoundImage>
    <MailSendSection>
      <TableText name>{props.member.email}</TableText>
      <ResendInvite onClick={() => props.resendInvite(props.member)}>
        Resend Invitation
      </ResendInvite>
    </MailSendSection>
  </AssigneesManageImgTextGrid>
);

export const CommFirstRoundLetterComp = ({
  text,
  backColor,
  size,
  fontSize,
}) => {
  const colorList = [
    { color: '#9A96F9' },
    { color: '#73C0FF' },
    { color: '#F9828B' },
    { color: '#F9D059' },
  ];
  let selectedIndex = 0;
  if (backColor < 4) {
    selectedIndex = backColor;
  } else {
    selectedIndex = backColor % 4;
  }

  return (
    <FirstWordRound
      backColor={colorList[selectedIndex].color}
      size={size}
      fontSize={fontSize}
    >
      {' '}
      {text.slice(0, 1).toUpperCase()}{' '}
    </FirstWordRound>
  );
};
export const CommFirstRoundLetterCompNew = ({ text, backColor, size }) => {
  const colorList = [
    { color: '#9A96F9' },
    { color: '#73C0FF' },
    { color: '#F9828B' },
    { color: '#F9D059' },
  ];
  const getInitials = string => {
    if (string === '' || string === undefined) return '';
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  let selectedIndex = 0;
  if (backColor < 4) {
    selectedIndex = backColor;
  } else {
    selectedIndex = backColor % 4;
  }

  return (
    <AssigneesManageImgTextGrid>
      <FirstWordRound backColor={colorList[selectedIndex].color} size={size}>
        {' '}
        {getInitials(text)}{' '}
      </FirstWordRound>
      <TableText name>{text}</TableText>
    </AssigneesManageImgTextGrid>
  );
};
