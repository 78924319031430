import { useState, useEffect, Fragment } from "react";
import moment from "moment-timezone";
import {
  CommonFlex,
  CommonGrid,
  CommonText,
  ColoredText,
  CommonImage,
  FormAlert,
  InputTextArea,
  InputWithLabelSection,
  InputLabel,
  RedStar,
  ModalCrossIcon,
} from "../../../styledComponents/common";
import {
  WhiteButton,
  ModalDeleteButton,
} from "../../../styledComponents/buttons";
import { ActivityTextSection } from "../../../styledComponents/members";
import { IconArrow } from "../../../styledComponents/common";
import {
  TimesheetDetailsPanelContainer,
  TimesheetDetailsTableRow,
  ExpandButton,
  DeleteButton,
  DetailsCollapseSection,
  DetailsInfoContainer,
  IdleTimeSectionContainer,
} from "./timesheetDetailsPanelStyles";

import { getHours, toHHMMSS, checkUrl, checkHtml } from "../../../utils/helper";

import DeleteTimeRangeComponent from "../DeleteTimeRangeComponent";

import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import { getHoursAndMinutes } from "../Daily/ManualTimeModal";

import deleteIcon from "../../../assets/img/icons/delete.svg";
import arrowIcon from "../../../assets/img/icons/arrow-right-black.svg";
import LeftArrow_3 from "../../../assets/img/icons/LeftArrow_3.svg";
import RightArrow_3 from "../../../assets/img/icons/RightArrow_3.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import SingleDatePicker from "../../../components/SingleDatePicker/SingleDatePicker";
import NoDataComponent from "components/NoDataComponent/NoDataComponent";

const TimesheetDetails = (props) => {
  const [expandedTimesheetId, setExpandedTimesheetId] = useState(null);

  const [reasons, setReasons] = useState({});
  const [saveErrors, setSaveErrors] = useState({});

  const [startTime, setStartTime] = useState({});
  const [endTime, setEndTime] = useState({});

  // time picker data
  const [timePickerStartTime, setTimePickerStartTime] = useState(null);
  const [timePickerEndTime, setTimePickerEndTime] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [totalTime, setTotalTime] = useState(null);

  const {
    getDailyTimesheets,
    clearDailyTimesheetsList,
    timesheetsList,
    userData,
    deleteTimesheetLoading,
    deleteTimesheet,
    selectedOrganization,
    selectedProject,
    selectedTask,
    timezone,
    fromPage,
    expandedAll,
    setExpandedAll,
    expandedTimesheets,
    setExpandedTimesheets,
    selectedPanelDate,
    onPanelDateChange,
    singlePanelDateChange,
    isPanelToday,
    setDetailsPanelIsOpen,
    disabledPrev,
    disabledNext,
  } = props;

  useEffect(() => {
    return () => {
      clearDailyTimesheetsList();
    };
  }, []);

  useEffect(() => {
    const totalDuration = timesheetsList.reduce(
      (total, timesheet) => total + (timesheet.duration || 0),
      0
    );
  
    const formattedDuration = getHours(toHHMMSS(totalDuration));
    
    setTotalTime(formattedDuration);
  }, [timesheetsList]);

  const handleExpandCollapseSection = (
    timesheetId,
    startTimestamp,
    endTimestamp
  ) => {
    const isAlreadyExpanded = expandedTimesheets.some(
      (ts) => ts.timesheetId === timesheetId
    );
    if (isAlreadyExpanded) {
      setExpandedTimesheets((prev) =>
        prev.filter((ts) => ts.timesheetId !== timesheetId)
      );
    } else {
      setStartTime((prev) => ({
        ...prev,
        [timesheetId]: startTimestamp,
      }));
      setEndTime((prev) => ({
        ...prev,
        [timesheetId]: endTimestamp,
      }));
      // setTimePickerStartTime(startTimestamp);
      // setTimePickerEndTime(endTimestamp);

      setExpandedTimesheets((prev) => [
        ...prev,
        { timesheetId },
      ]);
    }
  };

  const handleExpandAll = () => {
    if (expandedAll) {
      setExpandedTimesheets([]);
    } else {
      const allExpanded = timesheetsList.map((timesheet) => ({
        timesheetId: timesheet.id,
      }));
      setExpandedTimesheets(allExpanded);
    }
  
    setExpandedAll(!expandedAll);
  };

  useEffect(()=> {
    if(expandedAll){
      const allExpanded = timesheetsList.map((timesheet) => ({
        timesheetId: timesheet.id,
      }));
      setExpandedTimesheets(allExpanded);
    }
  }, [timesheetsList]);

  const handleStartTime = (timesheetId, date, time) => {
    if (date && time) {
      const formatted_time = time.replace(/ /g, "");
      const start_time = `${date}T${formatted_time}`;
  
      setStartTime((prev) => ({
        ...prev,
        [timesheetId]: timezone
          ? moment.tz(start_time, "YYYY-MM-DDTh:mmA", timezone)
          : moment(start_time, "YYYY-MM-DDTh:mmA"),
      }));
    } else {
      setStartTime((prev) => ({
        ...prev,
        [timesheetId]: "",
      }));
    }
  
    if (saveErrors && saveErrors[timesheetId]?.["time"]) {
      const errors = { ...saveErrors };
  
      delete errors[timesheetId]["time"];
  
      if (Object.keys(errors[timesheetId]).length === 0) {
        delete errors[timesheetId];
      }
  
      setSaveErrors(errors);
    }
  };  

  const handleEndTime = (timesheetId, date, time) => {
    if (date && time) {
      const formatted_time = time.replace(/ /g, "");
      const end_time = `${date}T${formatted_time}`;
  
      setEndTime((prev) => ({
        ...prev,
        [timesheetId]: timezone
          ? moment.tz(end_time, "YYYY-MM-DDTh:mmA", timezone)
          : moment(end_time, "YYYY-MM-DDTh:mmA"),
      }));
    } else {
      setEndTime((prev) => ({
        ...prev,
        [timesheetId]: "",
      }));
    }
  
    if (saveErrors && saveErrors[timesheetId]?.["time"]) {
      const errors = { ...saveErrors };
  
      delete errors[timesheetId]["time"];
  
      if (Object.keys(errors[timesheetId]).length === 0) {
        delete errors[timesheetId];
      }
  
      setSaveErrors(errors);
    }
  };  

  const checkError = (timesheetId, start, end) => {
    let formIsValid = true;
    const currentErrors = { ...saveErrors };
    const timesheetErrors = {};

    if (checkUrl(reasons[timesheetId])) {
      formIsValid = false;
      timesheetErrors["reason"] = "Reason cannot contain a URL.";
    } else if (checkHtml(reasons[timesheetId])) {
      formIsValid = false;
      timesheetErrors["reason"] = "Invalid input in reason.";
    } else if (!startTime || !endTime) {
      formIsValid = false;
      timesheetErrors["time"] = "Please select start and end time.";
    } else if (startTime[timesheetId] && startTime[timesheetId].isBefore(start, "minutes")) {
      formIsValid = false;
      timesheetErrors["time"] = "Invalid time input.";
    } else if (endTime[timesheetId] && endTime[timesheetId].isAfter(end, "minutes")) {
      formIsValid = false;
      timesheetErrors["time"] = "Invalid time input.";
    }

    if (Object.keys(timesheetErrors).length > 0) {
      currentErrors[timesheetId] = timesheetErrors;
    } else {
      delete currentErrors[timesheetId];
    }

    setSaveErrors(currentErrors);
    return formIsValid;
  };

  const handleDeleteIdleTime = (timesheetId, time) => {
    if (timesheetId && time && time.id && userData && userData.id) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          timesheet_id: timesheetId,
          user_id: userData.id,
          start: moment(time.start_timestamp).utc().unix(),
          end: moment(time.end_timestamp).utc().unix(),
          comment: "idle time",
          fromPage,
          date: moment(time.start_timestamp).format("YYYY-MM-DD"),
        };
        deleteTimesheet(payload);
        setTimeout(() => {
          // setExpandedTimesheets((prev) =>
          //   prev.filter((ts) => ts.timesheetId !== timesheetId)
          // );
          const dailyPayload = {
            organization_id: selectedOrganization.id,
            user_id: userData.id,
            date: moment(userData.date).format("YYYY-MM-DD"),
          };
          if (selectedProject && selectedProject.value) {
            dailyPayload.project_id = selectedProject.value;
          }
          if (selectedTask && selectedTask.value) {
            dailyPayload.task_id = selectedTask.value;
          }
          if (timezone) {
            dailyPayload.timezone = timezone;
          }
          getDailyTimesheets(dailyPayload);
        }, 800);
      }
    }
  };

  const handleDeleteTimesheet = (timesheetId, start, end) => {
    if(timesheetId){
      setDeleteId(timesheetId);
    }

    if (
      timesheetId &&
      startTime &&
      endTime &&
      checkError(timesheetId, start, end) &&
      userData &&
      userData.id
    ) {
      const startTimestamp = startTime[timesheetId].isSame(start, "minutes")
        ? start
        : startTime[timesheetId];
      const endTimestamp = endTime[timesheetId].isSame(end, "minutes") ? end : endTime[timesheetId];

      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          timesheet_id: timesheetId,
          user_id: userData.id,
          start: moment(startTimestamp).utc().unix(),
          end: moment(endTimestamp).utc().unix(),
          comment: reasons[timesheetId],
          fromPage,
          date: moment(startTimestamp).format("YYYY-MM-DD"),
        };
        deleteTimesheet(payload);
        setTimeout(() => {
          // setExpandedTimesheets((prev) =>
          //   prev.filter((ts) => ts.timesheetId !== timesheetId)
          // );
          const dailyPayload = {
            organization_id: selectedOrganization.id,
            user_id: userData.id,
            date: moment(userData.date).format("YYYY-MM-DD"),
          };
          if (selectedProject && selectedProject.value) {
            dailyPayload.project_id = selectedProject.value;
          }
          if (selectedTask && selectedTask.value) {
            dailyPayload.task_id = selectedTask.value;
          }
          if (timezone) {
            dailyPayload.timezone = timezone;
          }
          getDailyTimesheets(dailyPayload);
        }, 800);
      }
    }
  };
  
  const handleReasonChange = (timesheetId, value) => {
    setReasons((prev) => ({
      ...prev,
      [timesheetId]: value,
    }));
  };
  
  const onDateChange = (date) => {
    onPanelDateChange(date, userData.id);
  };


  return (
    <TimesheetDetailsPanelContainer>
      <ModalCrossIcon 
        src={crossIcon} alt="" 
        onClick={() => setDetailsPanelIsOpen(false)} 
      />
      {userData && userData.id && (
        <CommonFlex 
          direction="column" 
          gap="5px" 
          alignItems="stretch" 
          padding="10px 25px"
          margin="10px 0 0 0"
        >
          <CommonFlex justifyContent="space-between" padding="20px 0 0 0">
            <CommonFlex gap="15px">
              {userData.avatar[0]!=='/' ? (
                <CommonImage
                  src={userData.avatar}
                  size="40px"
                  alt=""
                  style={{ borderRadius: "50%", border: "1px solid #c2cce1" }}
                />
              ) : (
                <FirstRoundLetterComp
                  text={userData.name}
                  backColor={0}
                  color={userData.color}
                  size="40px"
                />
              )}
              <CommonText fontSize="13px">{userData.name}</CommonText>
            </CommonFlex>
            <InputWithLabelSection>
              <CommonFlex gap="2px" alignItems="flex-end">
                <SingleDatePicker
                  dateDisplayFormat="DD/MM/YYYY"
                  placeholder="Date"
                  date={selectedPanelDate}
                  onDateChange={onDateChange}
                  allowPreviousDates
                  disableFutureDates
                  customClassName='timesheetPanel'
                />
                <CommonFlex gap="0px">
                  <div style={{pointerEvents: disabledPrev ? "none" : "auto"}}>
                    <IconArrow
                      onClick={() => {
                        singlePanelDateChange("left", userData.id);
                      }}
                      padding="2px"
                      size="28px"
                      src={LeftArrow_3}
                      disabled={disabledPrev}
                    />
                  </div>
                  <div style={{pointerEvents: disabledNext ? "none" : "auto"}}>
                    <IconArrow
                      margin="0 0 0 2px"
                      onClick={() => {
                        if (!isPanelToday) {
                          singlePanelDateChange("right", userData.id);
                        }
                      }}
                      padding="2px"
                      size="28px"
                      src={RightArrow_3}
                      disabled={disabledNext}
                    />
                  </div>
                </CommonFlex>
              </CommonFlex>
            </InputWithLabelSection>
          </CommonFlex>
          <CommonText fontWeight="600" fontSize="13px" style={{textAlign: "right"}}>
            Total Time: {totalTime}
          </CommonText>
        </CommonFlex>
      )}

      <TimesheetDetailsTableRow header>
        <CommonText fontSize="12px" $label fontWeight="500">
          Start Time
        </CommonText>
        <CommonText fontSize="12px" $label fontWeight="500">
          End Time
        </CommonText>
        <CommonText fontSize="12px" $label fontWeight="500">
          Total Time
        </CommonText>
        <ColoredText
          fontSize="12px"
          primary 
          fontWeight="500"
          style={{cursor: "pointer"}}
          onClick={() => {
            handleExpandAll();
          }}
        >
          {expandedAll ? "Collapse all" : "Expand all"}
        </ColoredText>
      </TimesheetDetailsTableRow>

      {timesheetsList?.length > 0 ? (
      <>
        {timesheetsList.map((timesheet, index) => (
          <Fragment key={timesheet.id}>
            <TimesheetDetailsTableRow
              active={expandedTimesheets.some((ts) => ts.timesheetId === timesheet.id)}
            >
              <CommonText fontSize="13px">
                {timesheet.start_timestamp.format("h:mm a")}
              </CommonText>
              <CommonText fontSize="13px">
                {timesheet.end_timestamp.format("h:mm a")}
              </CommonText>
              <CommonText fontSize="13px">
                {getHours(toHHMMSS(timesheet.duration))}
              </CommonText>
              <ExpandButton
                onClick={() =>
                  handleExpandCollapseSection(
                    timesheet.id,
                    timesheet.start_timestamp,
                    timesheet.end_timestamp
                  )
                }
                active={expandedTimesheets.some((ts) => ts.timesheetId === timesheet.id)}
              >
                <CommonImage src={arrowIcon} alt="" />
              </ExpandButton>
            </TimesheetDetailsTableRow>
            <DetailsCollapseSection
              open={expandedTimesheets.some((ts) => ts.timesheetId === timesheet.id)}
              maxHeight={
                timesheet.idle_times && timesheet.idle_times.length > 0
                  ? `${292 + 20 + 30 * timesheet.idle_times.length}px`
                  : "292px"
              }
            >
              <DetailsInfoContainer>
                {timesheet.idle_times && timesheet.idle_times.length > 0 ? (
                  <IdleTimeSectionContainer>
                    <CommonGrid columns="80px auto">
                      <ColoredText type="error" fontSize="13px">
                        Idle Time
                      </ColoredText>
                      <CommonGrid columns="155px 90px 40px">
                        {timesheet.idle_times.map((time) => (
                          <Fragment key={time.id}>
                            <CommonText fontSize="13px">
                              {time.start_timestamp.format("h:mm a")} -{" "}
                              {time.end_timestamp.format("h:mm a")}
                            </CommonText>
                            <ColoredText type="blue" fontSize="13px">
                              {getHours(toHHMMSS(time.duration))}
                            </ColoredText>
                            <DeleteButton
                              onClick={() =>
                                handleDeleteIdleTime(timesheet.id, time)
                              }
                            >
                              <CommonImage src={deleteIcon} alt="" size="10px" />
                            </DeleteButton>
                          </Fragment>
                        ))}
                      </CommonGrid>
                    </CommonGrid>
                  </IdleTimeSectionContainer>
                ) : null}
                <CommonGrid
                  columns="auto auto"
                  justifyContent="flex-start"
                  margin={"15px 0 0"}
                  gap='7px'
                >
                  <CommonText fontSize="13px" $label fontWeight="500" margin="0">
                    Work Order:
                  </CommonText>
                  <CommonText fontSize="13px" margin="0" style={{ opacity: timesheet.work_order?"auto":"50%" }}>
                    {timesheet.work_order?timesheet.work_order.name:"(No work order)"}
                  </CommonText>
                </CommonGrid>
                <CommonGrid
                  columns="auto auto"
                  justifyContent="flex-start"
                  margin={"15px 0 0"}
                  gap='7px'
                >
                  <CommonText fontSize="13px" $label fontWeight="500" margin="0">
                    Job:
                  </CommonText>
                  <CommonText fontSize="13px" margin="0" style={{ opacity: timesheet.work_order?"auto":"50%" }}>
                    {timesheet.job?timesheet.job.name:"(No job)"}
                  </CommonText>
                </CommonGrid>
                {timesheet.task && (
                  <CommonGrid
                    columns="60px auto"
                    justifyContent="flex-start"
                    margin="10px 0 0"
                  >
                    <CommonText
                      fontSize="12px"
                      $label
                      fontWeight="500"
                      margin="0"
                    >
                      Task
                    </CommonText>
                    <CommonText fontSize="13px" margin="0">
                      {timesheet.task.name}
                    </CommonText>
                  </CommonGrid>
                )}
                <CommonGrid
                  columns="auto 50% auto"
                  margin="20px 0 15px"
                  alignItem="center"
                >
                  <CommonText fontSize="12px">Delete Time</CommonText>
                  <CommonGrid columns="100%" style={{ gridRowGap: "3px" }}>
                    <DeleteTimeRangeComponent
                      startTime={timesheet.start_timestamp}
                      setStartTime={handleStartTime}
                      timesheetId={timesheet.id}
                      endTime={timesheet.end_timestamp}
                      setEndTime={handleEndTime}
                    />
                    {saveErrors &&
                      saveErrors[timesheet.id] &&
                      saveErrors[timesheet.id]["time"] && (
                        <FormAlert margin="0">{saveErrors[timesheet.id]["time"]}</FormAlert>
                      )}
                  </CommonGrid>

                  {moment(endTime[timesheet.id]).isAfter(moment(startTime[timesheet.id])) ? (
                    <ColoredText type="blue" fontSize="12px">
                      {getHoursAndMinutes(
                        moment(endTime[timesheet.id]).diff(moment(startTime[timesheet.id]), "minutes")
                      )}
                    </ColoredText>
                  ) : (
                    <ColoredText type="error" fontSize="11.5px">
                      End time must be after start time
                    </ColoredText>
                  )}
                </CommonGrid>
                <InputWithLabelSection>
                  <InputLabel>
                    Reason <RedStar>*</RedStar>
                  </InputLabel>
                  <InputTextArea
                    id="reason-text-area"
                    placeholder="Why do you want to delete the timesheet?"
                    value={reasons[timesheet.id] || ""}
                    onChange={(e) => handleReasonChange(timesheet.id, e.target.value)}
                    height="60px"
                    error={saveErrors?.[timesheet.id]?.["reason"]}
                  />
                  {saveErrors?.[timesheet.id]?.["reason"] && (
                    <FormAlert margin="0 0 0 5px">
                      {saveErrors[timesheet.id]["reason"]}
                    </FormAlert>
                  )}
                </InputWithLabelSection>
                <CommonGrid
                  justifyContent="flex-end"
                  gap="12px"
                  margin={saveErrors[timesheet.id]?.["reason"] ? "0" : "8px 0 0 0"}
                >
                  <WhiteButton
                    type="cancel"
                    height="36px"
                    onClick={() => {
                      setExpandedTimesheets((prev) =>
                        prev.filter((ts) => ts.timesheetId !== timesheet.id)
                      );
                    }}
                  >
                    Cancel
                  </WhiteButton>
                  <ModalDeleteButton
                    height="36px"
                    padding={deleteTimesheetLoading && "0 5px"}
                    disabled={
                      !reasons[timesheet.id]?.trim()
                    }
                    onClick={() =>
                      handleDeleteTimesheet(
                        timesheet.id,
                        timesheet.start_timestamp,
                        timesheet.end_timestamp
                      )
                    }
                    width="100px"
                  >
                    {deleteTimesheetLoading && deleteId===timesheet.id  ? (
                      <ButtonTextLoader
                        loadingText="Deleting"
                        fontSize="12px"
                        fontWeight="400"
                      />
                    ) : (
                      "Delete"
                    )}
                  </ModalDeleteButton>
                </CommonGrid>
              </DetailsInfoContainer>
            </DetailsCollapseSection>
          </Fragment>
        ))}
      </>
      ) : (
        <NoDataComponent title="No data found." />
      )}

    </TimesheetDetailsPanelContainer>
  );
};

export default TimesheetDetails;
