import { Modal, ModalBody } from 'reactstrap';

import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../styledComponents/buttons';
import { CommonText } from '../../styledComponents/common';
import { ModalButtonSection } from '../../styledComponents/invoice';

const DeleteInvoicePopup = ({
  isOpen,
  toggle,
  organization_id,
  invoice_id,
  deleteInvoice,
  onDelete,
  isLoading,
  reloadInvoiceList,
}) => {
  const onDeleteInvoice = () => {
    const payload = {
      organization_id: organization_id,
      invoice_id: invoice_id,
      reloadInvoiceList,
    };
    deleteInvoice(payload);
    setTimeout(() => {
      toggle();
      if (onDelete) {
        onDelete();
      }
    }, 400);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `20vh`, maxWidth: `520px` }}
    >
      <ModalBody style={{ padding: `30px 0 15px` }}>
        <CommonText margin="0 0 0 30px">
          Are you sure you want to delete this invoice?
        </CommonText>
        <ModalButtonSection justifyContent="flex-end">
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            type="delete"
            padding={isLoading ? '0 15px' : '0 30px'}
            onClick={() => onDeleteInvoice()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              'Delete'
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default DeleteInvoicePopup;
